import * as React from "react";
import Box from "@mui/material/Box";
import { Modal, ModalContent, StyledBackdrop } from "../baseModal";
import { Grid, TextField, Typography, Tabs, Tab, CircularProgress } from "@mui/material";
import { get_friendly_token } from "../../service/calc";
import { getFormattedDate } from "../../service/date";
import { Timer } from "../UI/Timer/Timer";
import { DealButtons } from "../Blocks/Deal/DealButtons";
import { useEffect, useState } from "react";
import { ChatDealBlock } from "../Chats/Blocks/ChatDeal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { DealAPI } from "../../API/deal";

export function DealModal({ open, handleClose, deal }) {
  const [copied, setCopied] = React.useState(false);
  const [sellerText, setSellerText] = React.useState("");
  const [buyerText, setBuyerText] = React.useState("");

  const [sellerCaption, setSellerCaption] = React.useState("");
  const [buyerCaption, setBuyerCaption] = React.useState("");

  const [sellerDisabled, setSellerDisabled] = React.useState(false);
  const [buyerDisabled, setBuyerDisabled] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState("deal");
  const [actionName, setActionName] = React.useState('');

  useEffect(() => {
    switch (deal?.status) {
      case "В обработке":
        setSellerDisabled(false);
        setSellerText("Принять сделку");

        setBuyerDisabled(true);
        setBuyerText("Ожидание продавца");

        setSellerCaption("❗️Подтвердите или отклоните сделку.");
        setBuyerCaption("Ждем когда продавец примет сделку.");

        setActionName('SellerAcceptDeal')
        break;
      case "Создано":
        setBuyerDisabled(true);
        setSellerDisabled(true);

        setSellerText("Оформление");
        setBuyerText("Оформление");

        setSellerCaption("Идет создание сделки.");
        setBuyerCaption("Идет создание сделки.");
        break;
      case "Ожидается оплата":
        setBuyerDisabled(false);
        setSellerDisabled(true);

        setSellerText("Ожидание покупателя");
        setBuyerText("Я оплатил");

        setSellerCaption("Ожидание когда покупатель переведет деньги.");
        setBuyerCaption(
          "❗️ Будьте внимательны при переводе средств," +
            " убедитесь что правильно указали реквизиты для оплаты. " +
            " Если оплата произойдет иным способом, продавец вправе забраковать" +
            " сделку безх возврата средств!",
        );
        setActionName('BuyerPay')
        break;
      case "Ожидается подтверждение":
        setSellerDisabled(false)
        setSellerText("Отправить монеты");

        setBuyerDisabled(true);
        setBuyerText("Ожидание продавца");

        setSellerCaption(
          "❗️ Внимательно проверьте счет на поступление денег." +
            " Не полагайтесь на пришедшие сообщения." +
            " Зайдите на сайт/приложение и проверте наличие перевода." +
            " После отправки монет, невозможно вернуть их обратно!.",
        );
        setBuyerCaption("Ожидание когда продавец подтвердит получение");
        setActionName('SellerConfirmPay')
        break;
      case "Завершение":
        setBuyerDisabled(true);
        setSellerDisabled(true);

        setSellerText("Завершение");
        setBuyerText("Завершение");

        setSellerCaption(
          "Сделка завершается. После завершения сделка будет автоматически закрыта.",
        );
        setBuyerCaption(
          "Сделка завершается. После завершения сделка будет автоматически закрыта.",
        );
        break;
      case "Исполнено":
        setBuyerDisabled(true);
        setSellerDisabled(true);

        setSellerText("Исполнено");
        setBuyerText("Исполнено");

        setSellerCaption("Сделка успешно завершена.");
        setBuyerCaption("Сделка успешно завершена.");
        break;
      case "Обжалование":
        setBuyerDisabled(true);
        setSellerDisabled(true);

        setSellerText("Обжалование");
        setBuyerText("Обжалование");

        setSellerCaption(
          "⚠️Сделка находится на рассмотрении. Вы получите уведомление о ходе рассмотрения спорной ситуации. Пожалуйста, предоставьте в чате чеки и что-то что нужно будет тут допичсать и тд и тпдет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать дет тут допичсать ",
        );
        setBuyerCaption(
          "⚠️Сделка находится на рассмотрении. Вы получите уведомление о ходе рассмотрения спорной ситуации. Пожалуйста, предоставьте в чате чеки и что-то что нужно будет тут допичсать и тд и тп",
        );
        break;
      case "Отменена":
        setBuyerDisabled(true);
        setSellerDisabled(true);

        setSellerText("Отменена");
        setBuyerText("Отменена");

        setSellerCaption("❗️ Сделка была отменена.");
        setBuyerCaption("❗️ Сделка была отменена.");
        break;
      default:
        setBuyerDisabled(true);
        setSellerDisabled(true);

        setSellerText("Ошибка");
        setBuyerText("Ошибка");
        break;
    }
  }, [deal?.status]);
  const getComment = () => {
    // Очистить строку от символов переноса строки
    return deal.order.comment.replace(/\\n/g, "\n");
  };

  const copyRequestId = () => {
    if (deal?.request_id) {
      navigator.clipboard.writeText(deal.request_id);
      setCopied(true); // 2. Установка состояния "Скопировано"
      setTimeout(() => {
        setCopied(false); // 3. Установка обратно после 1 секунды
      }, 1000);
    }
  };
  const formatRequestId = (requestId) => {
    if (requestId.length > 10) {
      return `${requestId.slice(0, 20)}...`;
    }
    return requestId;
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    const dealUpdateData = {
      deal_id: deal.id,
      action: actionName,
    }
    const dealAPI = new DealAPI()
    dealAPI.updateDealStatus(dealUpdateData)
      .then((response) => {
        console.log(response.status)
      })
      .catch((error) =>
        setTimeout(() => {
          setIsLoading(false);
        }, 3000)
      )
    setIsLoading(false);

  }
  const renderDeal = () => {
    if (activeTab === "deal") {
      return (
        <>
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.32)",
                zIndex: 99999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress style={{ color: "#1976d2" }} />
            </div>
          )}
          <Box
            sx={{ width: "100%", height: "100%", typography: "body1" }}
            overflow={"auto"}
          >
            <Grid
              item
              xs={12}
              onClick={copyRequestId}
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
            >
              {copied
                ? "Скопировано!"
                : `Сделка №${formatRequestId(deal?.request_id)}`}
            </Grid>
            <br />
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              wrap={"nowrap"}
            >
              <Grid>{deal?.user_is_seller ? "Покупатель:" : "Продавец:"}</Grid>
              <Grid
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={12}
                style={{ textAlign: "right" }}
              >
                {deal?.user?.username} {deal?.user?.statistic}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              wrap={"nowrap"}
            >
              <Grid>Всего:</Grid>
              <Grid
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={12}
                style={{ textAlign: "right" }}
              >
                {get_friendly_token(deal.count_token, deal.token_name)}{" "}
                {deal.token_name}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              wrap={"nowrap"}
            >
              <Grid>Сумма:</Grid>
              <Grid
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={12}
                style={{ textAlign: "right" }}
              >
                {deal.amount *
                  get_friendly_token(deal.count_token, deal.token_name)}{" "}
                {deal.currency_name}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              wrap={"nowrap"}
            >
              <Grid item xs={6}>
                За 1 {deal?.token_name}:
              </Grid>
              <Grid
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={6}
                style={{ textAlign: "right" }}
              >
                {deal.amount} {deal?.currency_name}
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              wrap={"nowrap"}
            >
              <Grid>Оплата:</Grid>
              <Grid
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={12}
                style={{ textAlign: "right" }}
              >
                {deal?.pay_method}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              wrap={"nowrap"}
            >
              <Grid item xs={5}>
                Статус:
              </Grid>
              <Grid
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={6}
                style={{ textAlign: "right" }}
              >
                {deal?.status}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              wrap={"nowrap"}
            >
              <Grid item xs={5}>
                Закрыте через:
              </Grid>
              <Grid
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={6}
                style={{ textAlign: "right" }}
              >
                <Timer seconds={900} />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              wrap={"nowrap"}
            >
              <Grid item xs={5}>
                Дата создания:
              </Grid>
              <Grid
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={6}
                style={{ textAlign: "right" }}
              >
                {getFormattedDate(deal?.created_on)}
              </Grid>
            </Grid>

            {deal?.order?.comment ? (
              <Grid container item xs={12} justifyContent="space-between">
                <TextField
                  label="Комментарий продавца"
                  multiline
                  rows={3}
                  fullWidth
                  variant="standard"
                  value={getComment()}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              marginTop={1}
            >
              <Typography>
                {deal?.user_is_seller ? sellerCaption : buyerCaption}
              </Typography>
            </Grid>
          </Box>
          <DealButtons
            handleClose={handleClose}
            user_is_seller={deal?.user_is_seller}
            sellerText={sellerText}
            buyerText={buyerText}
            sellerCaption={sellerCaption}
            sellerDisabled={sellerDisabled}
            buyerDisabled={buyerDisabled}
            handleClick={handleClick}
          />
        </>
      );
    } else if (activeTab === "chat") {
      return <ChatDealBlock deal_id={deal?.id} />;
    }
  };

  return (
    <Modal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      slots={{ backdrop: StyledBackdrop }}
      m_top_xs="0%"
      m_top_xl="5%"
    >
      <ModalContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          maxWidth: 460,
          width: "100%",
        }}
        sizeXS="100%"
        sizeXL="60vh"
        // style={{  padding: 0}} Для чатасупер было бы
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
            zIndex: 999,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
        >
          <Tab label="Сделка" value="deal" />
          <Tab label="Чат" value="chat" />
        </Tabs>
        {renderDeal()}
      </ModalContent>
    </Modal>
  );
}
