import { Button, Grid } from "@mui/material";
import { useState } from "react";

export const AccountTable = ({ handleSetChapter }) => {
  const [isMobile] = useState(window.innerWidth <= 768);

  const btnStyle = {
    margin: "0 0 0",
    padding: "6px 0 0",
  };
  return isMobile ? (
    <Grid item xs={12} marginBottom={1}>
      <Grid
        container
        item
        display="block"
        padding="15px 0 0"
        margin="0 0 0"
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item xs={12}>
            <Button style={btnStyle} onClick={() => handleSetChapter("secure")}>
              Безопасность
            </Button>
          </Grid>
          <Grid container item xs={12}>
            <Button
              style={btnStyle}
              onClick={() => handleSetChapter("notification")}
            >
              Уведомления
            </Button>
          </Grid>
          <Grid container item xs={12}>
            <Button style={btnStyle} onClick={() => handleSetChapter("total")}>
              Объемы
            </Button>
          </Grid>
          <Grid container item xs={12}>
            <Button style={btnStyle} onClick={() => handleSetChapter("news")}>
              Новости
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid item xs={12} marginBottom={1}>
      <Grid
        container
        item
        display="block"
        padding="15px 0 0"
        margin="0 0 0"
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item xs={12}>
            <Button style={btnStyle} onClick={() => handleSetChapter("secure")}>
              Безопасность
            </Button>
          </Grid>
          <Grid container item xs={12}>
            <Button
              style={btnStyle}
              onClick={() => handleSetChapter("notification")}
            >
              Уведомления
            </Button>
          </Grid>
          <Grid container item xs={12}>
            <Button style={btnStyle} onClick={() => handleSetChapter("total")}>
              Объемы
            </Button>
          </Grid>
          <Grid container item xs={12}>
            <Button style={btnStyle} onClick={() => handleSetChapter("news")}>
              Новости
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
