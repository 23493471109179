import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TotalBlock } from "../../Blocks/Account/TotalBlock";
import { WalletAPI } from "../../../API/wallet";

export const TotalTable = () => {
  const [isMobile] = useState(window.innerWidth <= 768);
  const [totals, setTotals] = useState([]);

  useEffect(() => {
    const walletAPI = new WalletAPI();
    walletAPI
      .getWallets()
      .then((data) => {
        setTotals(data.data.wallets);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <Grid
      container
      marginTop={isMobile ? "2px" : "15px"}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {totals?.map((total, index) => (
        <div
          key={total?.token_name}
          style={{ marginTop: index !== 0 ? "10px" : "0" }}
        >
          <TotalBlock id={total?.token_name} total={total} />
        </div>
      ))}
    </Grid>
  );
};
