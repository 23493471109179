import * as React from "react";
import Box from "@mui/material/Box";
import { Modal, ModalContent, StyledBackdrop } from "../baseModal";
import {
  Grid,
  InputAdornment,
  TextField,
  Button,
  useMediaQuery,
  Typography, CircularProgress
} from "@mui/material";
import { get_friendly_token, get_max_limit } from "../../service/calc";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { OrderAPI } from "../../API/order";
import { uuidv4 } from "../../service/utils";
import { NotificationContext } from "../../context";
import { DealAPI } from "../../API/deal";

function ConfirmationModal({ open, handleClose, handleConfirm }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h2 id="confirmation-modal-title">Подтвердите сделку</h2>
        <p id="confirmation-modal-description">
          Вы уверены, что хотите создать эту сделку?
        </p>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button onClick={handleClose} sx={{ mr: 2 }}>
            Отмена
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="primary">
            Подтвердить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export function CreateDealModal({ open, handleClose, order }) {
  const [tokenName, setTokenName] = React.useState("");
  const [currencyName, setCurrencyName] = React.useState("");

  const [countTokenNano, setCountTokenNano] = React.useState("");
  const [countTokenFriendly, setCountTokenFriendly] = React.useState("");

  const [maxLimitTokenFriendly, setMaxLimitTokenFriendly] = React.useState("");
  const [minLimitTokenFriendly, setMinLimitTokenFriendly] = React.useState("");
  const [maxLimit, setMaxLimit] = React.useState("");
  const [minLimit, setMinLimit] = React.useState("");
  const [amount, setAmount] = React.useState("");

  const [countTokenFriendlyInput, setCountTokenFriendlyInput] =
    React.useState("");
  const [amountInput, setAmountInput] = React.useState("");
  const [payMethodInput, setPayMethodInput] = React.useState("Сбербанк");

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  // Функция для открытия окна подтверждения
  // const handleOpenConfirmation = () => {
    // setConfirmationOpen(true);

  // };

  // Функция для закрытия окна подтверждения
  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const { notification, setNotification } = useContext(NotificationContext);
  // Функция для обработки подтверждения
  const handleConfirm = () => {

    // setIsLoading(true);
    const dealAPI = new DealAPI();

    let dealData = {
      request_id: uuidv4(),
      count_token: countTokenNano,
      order_id: order.id,
      pay_method: payMethodInput,
    };
    setIsLoading(true);

    dealAPI
      .createDeal(dealData)
      .then((response) => {
        let notifData = {
          type: "notif",
          text: "Вы получите уведомление когда продавец подтвердит сделку.",
          level: "info",
          title: "Сделка создана!",
        };
        if (response.status === 200) {
          setIsLoading(false);
          setNotification(notifData);
        } else if (response.status === 409) {
          setIsLoading(false);
          setNotification(notifData);
        }
      })
      .catch((error) => {
        setNotification({
          type: "notif",
          text: "Сделка не создана! Попробуйте позже",
          level: "error",
          title: "Ошибка!",
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      });
    setIsLoading(true);
  };

  // Функция для форматирования числа с учетом удаления незначащих нулей
  const formatNumber = (value, decimalPlaces) => {
    if (typeof value !== "number") {
      return value;
    }
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      return value;
    }
    return parsedValue.toFixed(decimalPlaces).replace(/\.?0+$/, "");
  };

  // Загрузочное
  React.useEffect(() => {
    if (order) {
      // Общие поля
      setCurrencyName(order.currency_name);
      setTokenName(order.token_name);

      let f_tokens = get_friendly_token(order.count_token, order.token_name);
      setCountTokenFriendly(formatNumber(f_tokens, 12));
      setCountTokenNano(order.count_token);

      setMinLimit(formatNumber(order.min_limit, 2));
      setAmount(formatNumber(order.amount, 2));
      setAmountInput(formatNumber(order.min_limit, 2));

      // Поля ввода
      let minLimitValue = order.min_limit / order.amount;
      let maxLimitValue = f_tokens * order.amount;
      setMaxLimit(formatNumber(maxLimitValue, 2));
      setMinLimitTokenFriendly(formatNumber(minLimitValue, 12));
      setMaxLimitTokenFriendly(formatNumber(f_tokens, 12));
      setCountTokenFriendlyInput(formatNumber(minLimitValue, 12));
    }
  }, [order]);

  const handleInputPayMethod = (event) => {
    let value = event.target.value;
    setPayMethodInput(value);
  };
  const handleInputSum = (event) => {
    let value = event.target.value;
    if (!value) {
      setAmountInput("");
      return;
    }
    value = parseFloat(value);
    if (value < minLimit) {
      console.log("Меньше минимального!");
    }
    if (value > maxLimit) {
      console.log("Больше максимального!");
    }

    let newCountTokenInput = value / amount;
    setAmountInput(formatNumber(value, 2));
    setCountTokenFriendlyInput(formatNumber(newCountTokenInput, 5));
  };

  const handleInputCount = (event) => {
    let value = event.target.value;
    if (!value) {
      setCountTokenFriendlyInput("");
      return;
    }
    value = parseFloat(value);
    if (value < minLimitTokenFriendly) {
      console.log("Меньше минимального!");
    }
    if (value > maxLimitTokenFriendly) {
      console.log("Больше максимального!");
    }
    let newAmountInput = value * amount;
    setCountTokenFriendlyInput(formatNumber(value, 5));
    setAmountInput(formatNumber(newAmountInput, 2));
  };

  const handleBuyAll = () => {
    setCountTokenFriendlyInput(formatNumber(maxLimitTokenFriendly, 12));
    setAmountInput(formatNumber(maxLimit, 2));
  };
  const getComment = () => {
    // Очистить строку от символов переноса строки
    return order.comment.replace(/\\n/g, "\n");
  };

  return (
    <Modal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      slots={{ backdrop: StyledBackdrop }}
      m_top_xs="0%"
      m_top_xl="5%"
    >
      <ModalContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          maxWidth: 460,
        }}
        sizeXS="100%"
        sizeXL="43hv"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
            zIndex: 999,
          }}
        >
          <CloseIcon />
        </IconButton>
        <ConfirmationModal
          open={confirmationOpen}
          handleClose={handleCloseConfirmation}
          handleConfirm={handleConfirm}
        />
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.32)",
              zIndex: 99999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "#1976d2" }} />
          </div>
        )}
        <Box sx={{ width: "100%", typography: "body1" }} overflow={"auto"}>
          Создать сделку
          <br />
          <br />
          <Grid container item xs={12}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="sum_currency"
                label="Сумма"
                sx={{ width: "100%" }}
                value={amountInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {currencyName}
                    </InputAdornment>
                  ),
                }}
                type={"number"}
                onChange={handleInputSum}
                required
              />
              {!isSmallScreen && (
                <Button
                  onClick={handleBuyAll}
                  style={{
                    fontSize: "10px",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  Купить все
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="sum_token_friendly"
                label="Всего токенов"
                sx={{ width: "100%" }}
                value={countTokenFriendlyInput}
                style={{
                  margin: isSmallScreen && "10px 0 0 0",
                }}
                type={"number"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{tokenName}</InputAdornment>
                  ),
                }}
                onChange={handleInputCount}
                required
              />
            </Grid>
            {isSmallScreen && (
              <Button
                onClick={handleBuyAll}
                style={{
                  fontSize: "10px",
                  padding: 0,
                  margin: 0,
                }}
              >
                Купить все
              </Button>
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            wrap={"nowrap"}
          >
            <Grid>Всего:</Grid>
            <Grid
              justifyContent="flex-end"
              alignItems="center"
              item
              xs={12}
              style={{ textAlign: "right" }}
            >
              {get_friendly_token(order.count_token, order.token_name)}{" "}
              {order.token_name}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            wrap={"nowrap"}
          >
            <Grid>Лимиты:</Grid>
            <Grid
              justifyContent="flex-end"
              alignItems="center"
              item
              xs={12}
              style={{ textAlign: "right" }}
            >
              {order.min_limit} -{" "}
              {get_max_limit(order.count_token, order.token_name, order.amount)}{" "}
              {order.currency_name}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            wrap={"nowrap"}
          >
            <Grid>Продавец:</Grid>
            <Grid
              justifyContent="flex-end"
              alignItems="center"
              item
              xs={12}
              style={{ textAlign: "right" }}
            >
              {order?.user?.username} {order?.user?.statistic}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            wrap={"nowrap"}
          >
            <Grid item xs={6}>
              Время на оплату:
            </Grid>
            <Grid
              justifyContent="flex-end"
              alignItems="center"
              item
              xs={6}
              style={{ textAlign: "right" }}
            >
              {order?.time_pay_minutes} мин.
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            wrap={"nowrap"}
          >
            <Grid>Оплата:</Grid>
            <Grid justifyContent="center" alignItems="center">
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={payMethodInput}
                  onChange={handleInputPayMethod}
                  label="Age"
                >
                  <MenuItem value="Сбербанк">Сбербанк</MenuItem>
                  <MenuItem value="Тинкофф">Тинкофф</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {order?.comment ? (
            <Grid container item xs={12} justifyContent="space-between">
              <TextField
                label="Комментарий продавца"
                multiline
                rows={3}
                fullWidth
                variant="standard"
                value={getComment()}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            marginTop={1}
          >
            <Typography>
              ❗️Будте внимательны при проведении сделки <br />
              Какой-то текст о том типо "Вы проебали бабки, мы Вас предупреждали
              тут"
            </Typography>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            mt: 2,
            marginBottom: "5%",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            sx={{ mr: 2 }}
            onClick={handleConfirm}
          >
            Создать сделку
          </Button>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Отмена
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
}
