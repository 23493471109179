import { OrdersPage } from "../pages/DataPages/Orders/OrdersPage";
import { DealsPage } from "../pages/DataPages/Deals/DealsPage";
import { WalletsPage } from "../pages/DataPages/Wallets/WalletsPage";
import { AccountPage } from "../pages/DataPages/Account/AccountPage";

export const privateRoutes = [
  { path: "/market", component: OrdersPage, exact: true },
  { path: "/deal", component: DealsPage, exact: true },
  { path: "/wallets", component: WalletsPage, exact: true },
  { path: "/account", component: AccountPage, exact: true },
  { path: "*", component: OrdersPage, exact: true },
];

export const publicRoutes = [
  { path: "/market", component: OrdersPage, exact: true },
  { path: "/deal", component: DealsPage, exact: true },
  { path: "/wallets", component: WalletsPage, exact: true },
  { path: "/account", component: AccountPage, exact: true },
  { path: "*", component: OrdersPage, exact: true },
];
