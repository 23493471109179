import { BaseAPI } from "./base";
import { getAuthToken } from "../service/auth";

const baseUrl = "https://order.pyostr.ru/api/v1";
// const baseUrl = "http://172.17.0.1:8006/api/v1";

export class DealAPI extends BaseAPI {
  constructor() {
    super(baseUrl);
  }

  async getDeals(only_active) {
    let headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };
    let data = {
      only_active: only_active,
    };
    return this.get("deal/getDeals", data, headers);
  }
  async getDeal(deal_id) {
    let data = {
      deal_id: deal_id,
    };
    return this.get("deal/service/getDeal", data);
  }

  async updateDealStatus(data) {
    // deal_id and action
    let headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };
    return this.post(`deal/updateDealStatus`, data, headers);
  }

  async createDeal(data) {
    // deal_id and action
    let headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };
    return this.post(`deal/createDeal`, data, headers);
  }

}
