import { Grid, Tab, Tabs } from "@mui/material";
import { WalletsTable } from "../../../components/Tables/Wallets/WalletsTable";
import { WalletHistoryTable } from "../../../components/Tables/Wallets/WalletHistoryTable";
import * as React from "react";
import { useState } from "react";

export function WalletsPage() {
  const [activeTab, setActiveTab] = useState("wallets");
  const [isMobile] = useState(window.innerWidth >= 768);

  const renderMobile = () => {
    if (activeTab === "wallets") {
      return <WalletsTable />;
    } else if (activeTab === "history") {
      return <WalletHistoryTable />;
    }
  };
  return (
    <Grid container spacing={1}>
      {isMobile ? (
        <>
          <Grid item xs={3} margin={3}>
            <WalletsTable />
          </Grid>
          <Grid item xs={7}>
            <WalletHistoryTable />
          </Grid>
        </>
      ) : (
        <>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            style={{
              padding: "10px",
            }}
          >
            <Tab label="Кошельки" value="wallets" />
            <Tab label="История" value="history" />
          </Tabs>
          {renderMobile()}
        </>
      )}
    </Grid>
  );
}
