export const tokens = [
  { key: "TON", name: "TON" },
  { key: "BTC", name: "BTC" },
  { key: "USDT", name: "USDT" },
];

export const currency = [
  { key: "RUB", name: "RUB" },
  { key: "UAH", name: "UAH" },
  { key: "USD", name: "USD" },
];

export const payMethods = ["Сбербанк", "Тинкофф", "Альфа-банк", "Монобанк", "Пидорбанк"];

export const _timeToPay = [
  { key: 15, name: 15 },
  { key: 30, name: 30 },
  { key: 60, name: 60 },
];

export const factorNano = {
  TON: 1_000_000_000,
  BTC: 1_000_000,
  USDT: 100,
};

export const get_friendly_token = (tokens, tokenName) => {
  return tokens / factorNano[tokenName];
};

export const get_nano_token = (tokens, tokenName) => {
  return tokens * factorNano[tokenName];
};

export const get_max_limit = (tokens, tokenName, amount) => {
  return get_friendly_token(tokens, tokenName) * amount;
};
