import React, { useEffect, useState } from "react";
import { UserAPI } from "../../../API/user";
import PropTypes from "prop-types";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";

export const SecureBlock = () => {
  const [user, setUser] = useState();
  const [isMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const userAPI = new UserAPI();

    userAPI
      .getUser()
      .then((response) => {
        if (response.status === 200) {
          setUser(response.data);
        }
      })
      .catch((error) => {
        console.log("Ошибка получения пользователя", error);
      });
  }, []);

  const handlePhoneChange = (event) => {
    // Обработчик изменения номера телефона
  };

  const handlePhoneChangeClick = () => {
    // Обработчик нажатия на кнопку "Сменить" для номера телефона
  };

  return (
    <Grid container item xs={12} padding={isMobile ? 1 : 0.5}>
      <Grid item xs={12} container height="50px">
        <Grid item xs={5} md={2}>
          <TextField
            label="Ник"
            variant="outlined"
            size="small"
            value={user?.username || ""}
            onChange={handlePhoneChange}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Номер телефона"
            variant="outlined"
            size="small"
            value={user?.phone || ""}
            onChange={handlePhoneChange}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="outlined" onClick={handlePhoneChangeClick}>
                    Сменить
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Телеграм"
            variant="outlined"
            size="small"
            value={user?.telegram_id || ""}
            onChange={handlePhoneChange}
            style={{}}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="outlined" onClick={handlePhoneChangeClick}>
                    Сменить
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Почта"
            variant="outlined"
            size="small"
            value={user?.email || ""}
            onChange={handlePhoneChange}
            style={{}}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="outlined" onClick={handlePhoneChangeClick}>
                    Сменить
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

SecureBlock.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.string,
    telegram_id: PropTypes.number,
    avatar: PropTypes.any,
    statistic: PropTypes.string,
    is_admin: PropTypes.bool,
    id: PropTypes.number,
  }),
};
