import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { SessionsBlock } from "./SessionBlock";
import { WalletUpModal } from "../../Modals/WalletUpModal";
import { get_friendly_token } from "../../../service/calc";
import {
  AttachMoneyOutlined,
  CurrencyBitcoinOutlined,
  DiamondOutlined,
} from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center", // Центрируем содержимое по горизонтали и вертикали
}));

const CVGData = {
  TON: <DiamondOutlined />,
  BTC: <CurrencyBitcoinOutlined />,
  USDT: <AttachMoneyOutlined />,
};

export const TotalBlock = ({ total }) => {
  const [isMobile] = useState(window.innerWidth <= 768);

  return (
    <Grid
      item
      xs={12}
      style={{
        width: isMobile ? "100%" : "520px",
      }}
    >
      <Grid container spacing={2} style={{ height: "100%" }}>
        <Grid item xs={3}>
          <Item style={{ height: "99%" }}>
            <div style={{ display: "inline-block" }}>
              <div>{CVGData[total?.token_name]}</div>
              <div>{total?.token_name}</div>
            </div>
          </Item>
        </Grid>
        <Grid item xs={9}>
          <Item style={{ height: "99%" }}>
            <Grid container item xs={12} spacing={1}>
              <Grid container item xs={12} justifyContent="space-between">
                <Grid container item xs={12} justifyContent="space-between">
                  <Grid>Баланс:</Grid>
                  <Grid justifyContent="center" alignItems="center">
                    {get_friendly_token(total?.balance, total?.token_name)}
                  </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="space-between">
                  <Grid>Продано:</Grid>
                  <Grid justifyContent="center" alignItems="center">
                    {get_friendly_token(total?.total_sell, total?.token_name)}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12} justifyContent="space-between">
                <Grid container item xs={12} justifyContent="space-between">
                  <Grid>Escrow:</Grid>
                  <Grid justifyContent="center" alignItems="center">
                    {get_friendly_token(total?.escrow, total?.token_name)}
                  </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="space-between">
                  <Grid>Куплено:</Grid>
                  <Grid justifyContent="center" alignItems="center">
                    {get_friendly_token(total?.total_buy, total?.token_name)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Item>
        </Grid>
        <Grid item xs={12}>
          {/*<Item style={{ display: 'flex', justifyContent: 'space-around', height: '75%' }}>*/}
          {/*<Button style={{ padding: 0, margin: 0 }}>Вывести</Button>*/}
          {/*</Item>*/}
        </Grid>
      </Grid>
    </Grid>
  );
};

TotalBlock.propTypes = {
  total: PropTypes.shape({
    session_id: PropTypes.string,
    created_on: PropTypes.string,
  }),
};
