import { Grid } from "@mui/material";
import { DealTable } from "../../../components/Tables/Deals/DealsTable";

export function DealsPage() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <DealTable />
      </Grid>
    </Grid>
  );
}
