import { Grid, Tab, Tabs } from "@mui/material";
import { OrderTable } from "../../../components/Tables/Orders/OrderTable";
import { useState } from "react";
import { CreateOrderForm } from "../../../components/Blocks/Order/CreateOrderForm";
import * as React from "react";

export function OrdersPage() {
  const [activeTab, setActiveTab] = useState("market");

  return (
    <Grid container item xs={12} margin={0} padding={0}>
      <Grid item xs={12}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
        >
          <Tab label="Маркет" value="market" />
          <Tab label="Мои объявления" value="myOrder" />
        </Tabs>
      </Grid>
      <Grid style={{ height: "100%" }} item xs={12}>
        {activeTab === "market" && <OrderTable userOrders={false} />}
        {activeTab === "myOrder" && <OrderTable userOrders={true} />}
      </Grid>
    </Grid>
  );
}
