import { getAuthToken } from "./auth";

export class SocketDeal {
  constructor() {
    this.socket = null;
    this.connected = false;
    this.listeners = {};
    this.connect();
  }

  connect = () => {
    const token = getAuthToken();
    this.socket = new WebSocket("wss://order.pyostr.ru/api/v1/ws/dealAndOrder/" + token);
    // this.socket = new WebSocket('ws://172.17.0.1:8006/api/v1/ws/dealAndOrder/' + token);

    this.socket.onopen = () => {
      console.log("Connected to socket Deal");
      this.connected = true;
      this.reconnectInterval && clearInterval(this.reconnectInterval);
    };

    this.socket.onclose = () => {
      console.log("Disconnected from socket Deal");
      this.connected = false;
      this.reconnect();
    };

    this.socket.onerror = (error) => {
      console.error("Socket error Deal:", error);
      this.reconnect();
    };

    this.socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log(message)
      const { type, data } = message;
      if (type && this.listeners[type]) {
        this.listeners[type].forEach((listener) => {
          listener(data);
        });
      }
    };
  };

  reconnect = () => {
    if (!this.connected) {
      console.log("Reconnecting to socket in 2 seconds...");
      this.reconnectInterval = setInterval(() => {
        if (!this.connected) {
          this.connect();
        } else {
          clearInterval(this.reconnectInterval);
        }
      }, 2000);
    }
  };

  on = (type, callback) => {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  };

  off = (type, callback) => {
    if (this.listeners[type]) {
      this.listeners[type] = this.listeners[type].filter(
        (listener) => listener !== callback,
      );
    }
  };

  close = () => {
    if (this.socket) {
      this.socket.close();
    }
  };
}
