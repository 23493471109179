import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { UserAPI } from "../../API/user";
import { useContext, useState } from "react";
import { AuthContext } from "../../context";
import { Tab, Tabs } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Все права защищены © "}
      <Link color="inherit" href="https://google.com/"></Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

const RegisterForm = ({ setTabValue }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false); // Состояние для галочки
  const { isAuth, setIsAuth } = useContext(AuthContext);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClick = () => {
    setTabValue("login");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Проверка на пустые поля
    const errors = {};
    let hasError = false;
    if (formData.username.trim() === "") {
      errors.username = true;
      hasError = true;
    }
    if (formData.password.trim() === "") {
      errors.password = true;
      hasError = true;
    }
    if (formData.confirmPassword.trim() === "") {
      errors.confirmPassword = true;
      hasError = true;
    }

    if (hasError) {
      setFormErrors(errors);
      setFormErrorMessage("Заполните все поля");
      return;
    }

    // Проверка на совпадение паролей
    if (formData.password !== formData.confirmPassword) {
      setFormErrorMessage("Пароли не совпадают");
      return;
    }

    // Проверка на согласие с условиями
    if (!agreedToTerms) {
      setFormErrorMessage("Подтвердите согласие с правилами");
      return;
    }

    const userAPI = new UserAPI();
    const data = {
      username: formData.username,
      password: formData.password,
    };
    userAPI
      .singUP(data)
      .then((response) => {
        let access_token = response.data.access_token;
        localStorage.setItem("auth", access_token);
        setIsAuth(true);
      })
      .catch((error) => {
        console.log("Авторизация error", error);
        setIsAuth(false);
      });
    // Очищаем ошибки и сообщение
    setFormErrors({});
    setFormErrorMessage("");
  };

  // Функция для обновления состояния галочки
  const handleCheckboxChange = (event) => {
    setAgreedToTerms(event.target.checked);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Регистрация
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Имя пользователя"
                  name="username"
                  autoComplete="username"
                  error={!!formErrors.username}
                  onChange={handleInputChange}
                  helperText={formErrors.username && "Заполните это поле"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  error={!!formErrorMessage}
                  onChange={handleInputChange}
                  helperText={
                    (formErrors.password && "Заполните это поле") ||
                    (formErrorMessage && "Пароли не совпадают")
                  }
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={handleClickShowPassword}
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </Button>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Подтвердите пароль"
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  autoComplete="new-password"
                  error={!!formErrors.confirmPassword}
                  onChange={handleInputChange}
                  helperText={
                    formErrors.confirmPassword && "Заполните это поле"
                  }
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={handleClickShowPassword}
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </Button>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedToTerms}
                      onChange={handleCheckboxChange}
                      value="agreedToTerms"
                      color="primary"
                    />
                  }
                  label="Я согласен с правилами торговли."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!agreedToTerms} // Деактивируем кнопку, если галочка не установлена
            >
              Регистрация
            </Button>

            <Grid container justifyContent="flex-end">
              <Grid item>
                Уже есть аккаунт?
                <Link href="#" variant="body2" onClick={handleClick}>
                  Войти
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

const AuthForm = ({ setTabValue }) => {
  const { isAuth, setIsAuth } = useContext(AuthContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userAPI = new UserAPI();
    userAPI
      .singIN(data)
      .then((response) => {
        let access_token = response.data.access_token;
        localStorage.setItem("auth", access_token);
        setIsAuth(true);
      })
      .catch((error) => {
        console.log("Авторизация error", error);
        setIsAuth(false);
      });
  };
  const handeClick = () => {
    setTabValue("register");
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Авторизация
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Имя пользователя"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Пароль"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/*<FormControlLabel*/}
            {/*  control={<Checkbox value="remember" color="primary" />}*/}
            {/*  label="Remember me"*/}
            {/*/>*/}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Войти
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Забыли пароль?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" onClick={handeClick}>
                  {"Регистрация"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};
export const AuthPage = () => {
  const [tabValue, setTabValue] = useState("login");
  const { isAuth, setIsAuth } = useContext(AuthContext);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TabContext value={tabValue}>
            <TabPanel value="login">
              <AuthForm setTabValue={setTabValue} />
            </TabPanel>
            <TabPanel value="register">
              <RegisterForm setTabValue={setTabValue} />
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
