import { BaseAPI } from "./base";
import { getAuthToken } from "../service/auth";

const baseUrl = "https://user.pyostr.ru/api/v1";

export class UserAPI extends BaseAPI {
  constructor() {
    super(baseUrl);
  }

  async getUser() {
    let headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };
    return this.get("auth/getUser", null, headers);
  }

  async singIN(data) {
    return this.post("auth/sing-in", data);
  }

  async singUP(data) {
    return this.post("auth/sing-up", data);
  }
}
