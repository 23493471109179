import * as React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { css, styled } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal/Modal";

export const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

export const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

export const Modal = styled(BaseModal)(
  ({ m_top_xs, m_top_xl }) => css`
    position: fixed;
    z-index: 1300;
    top: 0; /* Устанавливаем верхнюю границу на 0 */
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-start; /* Выравниваем по верхнему краю */
    justify-content: center;
    height: 100%;
    /* Медиа-запросы для разных размеров экрана */
    @media screen and (max-width: 600px) {
      margin-top: ${m_top_xs};
    }
    @media screen and (min-width: 601px) {
      margin-top: ${m_top_xl};
    }
  `,
);

export const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export const ModalContent = styled("div")(
  ({ theme, sizeXS, sizeXL }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 10px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};
    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
    /* Медиа-запросы для разных размеров экрана */
    @media screen and (max-width: 600px) {
      height: ${sizeXS};
    }
    @media screen and (min-width: 601px) {
      height: ${sizeXL};
    }
  `,
);
