import * as React from "react";
import { Modal, ModalContent, StyledBackdrop } from "../baseModal";
import { CircularProgress, Grid, Tab, Tabs } from "@mui/material";

import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { CreateOrderForm } from "../Blocks/Order/CreateOrderForm";

export function CreateOrderModal({ open, handleClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("buy");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      slots={{ backdrop: StyledBackdrop }}
      m_top_xs="0%"
      m_top_xl="5%"
    >
      <ModalContent
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 460,
        }}
        sizeXS="100%"
        sizeXL="43hv"
      >
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.32)",
              zIndex: 99999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "#1976d2" }} />
          </div>
        )}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
            zIndex: 999,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
          >
            <Tab label="Купить" value="buy" />
            <Tab label="Продать" value="sell" />
          </Tabs>
        </Grid>
        <Grid style={{ height: "100%" }}>
          {activeTab === "buy" && (
            <CreateOrderForm handleClose={handleClose} isSell={false} />
          )}
          {activeTab === "sell" && (
            <CreateOrderForm handleClose={handleClose} isSell={true} />
          )}
        </Grid>
      </ModalContent>
    </Modal>
  );
}
