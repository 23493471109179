import { BaseAPI } from "./base";
import { getAuthToken } from "../service/auth";

const baseUrl = "https://notif.pyostr.ru/api/v1";

export class NotificationAPI extends BaseAPI {
  constructor() {
    super(baseUrl);
  }

  async getNotificationSettings() {
    let headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };
    return this.get("notification/getSettings", null, headers);
  }

  async updateNotificationSettings(data) {
    let headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };
    return this.put("notification/updateSettings", data, headers);
  }

}
