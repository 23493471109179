import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import {
  AuthContext,
  ColorModeContext,
  CurrencyContext,
  NotificationContext, UserContext
} from "./context";
import AppRouter from "./components/AppRouter";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Navbar from "./components/Nav/Sidebar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BottomNav } from "./components/Nav/BottomBar";
import { AuthPage } from "./pages/Auth/AuthPage";
import { UserAPI } from "./API/user";
import { AlertNotification } from "./AlertNotification";

const ChatUpdate = (text) => {
  let jsonObject = JSON.parse(text.replace(/'/g, '"'));
  console.log(3, jsonObject);
};
function App() {
  const [notification, setNotification] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Проверяем ширину окна, считаем мобильным, если меньше или равно 768px
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [mode, setMode] = React.useState(() => {
    // Попробуйте сначала получить режим из localStorage
    const savedMode = localStorage.getItem("themeMode");
    // Вернуть сохраненный режим или по умолчанию 'light'
    return savedMode || "light";
  });

  const [currency, setCurrency] = useState(() => {
    const storageCurrency = localStorage.getItem("currency");
    return storageCurrency ? storageCurrency : "RUB";
  });

  const [user, setUser] = useState(null)
  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    localStorage.setItem("currency", currency);
  }, [currency]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          // Сохраняем новый режим в localStorage
          localStorage.setItem("themeMode", newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  let token = localStorage.getItem("auth");

  useEffect(() => {
    if (token) {
      const userAPI = new UserAPI();
      userAPI
        .getUser()
        .then((response) => {
          if (response.status === 200) {
            setIsAuth(true);
            setUser(response.data)
            return;
          }
          setIsAuth(false);
        })
        .catch((error) => {
          setIsAuth(false);
        });
    }
    setLoading(false);
  }, [isAuth, token]);

  useEffect(() => {
    if (!token) {
      return;
    }
    let SocketNotification = "wss://notif.pyostr.ru/api/v1/socket/ws/";
    let socketURL = SocketNotification + token;

    const socket = new WebSocket(socketURL);

    // Connection opened
    socket.addEventListener("open", () => {
      socket.send("Connection established");
    });

    // Listen for messages
    socket.addEventListener("message", (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log("Message from server NOTIF", data);

        if (data?.type === "notif") {
          setNotification({
            title: data.title,
            text: data.text,
            type: data.type,
            level: data.level,
          });
        } else if (data?.type === "chatUpdate") {
          ChatUpdate(data.text);
        }
      } catch (error) {
        // Обработка случая, когда event.data не является JSON
      }
    });
  }, []);

  if (!token) {
    return (
      <AuthContext.Provider
        value={{
          isAuth,
          setIsAuth,
        }}
      >
        <AuthPage />
      </AuthContext.Provider>
    );
  }
  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
        isLoading,
      }}
    >
      <BrowserRouter>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CurrencyContext.Provider value={{ currency, setCurrency }}>
              <NotificationContext.Provider
                value={{ notification, setNotification }}
              >
                <UserContext.Provider
                  value={{ user, setUser }}
                  >
                  {notification && (
                    <AlertNotification
                      data={notification}
                      setNotification={setNotification}
                    />
                  )}
                  {!isMobile && <Navbar />}
                  <div style={{ marginBottom: isMobile && "56px" }}>
                    <AppRouter />
                  </div>
                  {isMobile && <BottomNav />}
                </UserContext.Provider>
              </NotificationContext.Provider>
            </CurrencyContext.Provider>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
