import * as React from "react";
import Box from "@mui/material/Box";
import { Modal, ModalContent, StyledBackdrop } from "../baseModal";
import { Grid } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { get_friendly_token } from "../../service/calc";
import { getFormattedDate } from "../../service/date";

export function WalletHistoryModal({ open, handleClose, operation }) {
  const [copied, setCopied] = React.useState(false);

  const copyRequestId = () => {
    if (operation?.request_id) {
      navigator.clipboard.writeText(operation.request_id);
      setCopied(true); // 2. Установка состояния "Скопировано"
      setTimeout(() => {
        setCopied(false); // 3. Установка обратно после 1 секунды
      }, 1000);
    }
  };
  const formatRequestId = (requestId) => {
    if (requestId.length > 10) {
      return `${requestId.slice(0, 20)}...`;
    }
    return requestId;
  };

  const getType = (type) => {
    switch (type) {
      case "in":
        return "Зачисление";
      case "out":
        return "Исходящая";
      case "from_ESCROW":
        return "Разморозка";
      case "to_ESCROW":
        return "Заморозка";
      default:
        return "";
    }
  };
  return (
    <Modal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      slots={{ backdrop: StyledBackdrop }}
      m_top_xs="0%"
      m_top_xl="5%"
    >
      <ModalContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          maxWidth: 460,
          width: "100%",
        }}
        sizeXS="100%"
        sizeXL="60vh"
        // style={{  padding: 0}} Для чатасупер было бы
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
            zIndex: 999,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ width: "100%", typography: "body1" }} overflow={"auto"}>
          <Grid
            item
            xs={12}
            onClick={copyRequestId}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {copied
              ? "Скопировано!"
              : `Операция №${formatRequestId(operation?.request_id)}`}
          </Grid>
          <br />
          <br />
          <Grid container item xs={12} justifyContent="space-between">
            <Grid>Токен:</Grid>
            <Grid justifyContent="center" alignItems="center">
              {operation?.token_name}
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid>Тип операции:</Grid>
            <Grid justifyContent="center" alignItems="center">
              {getType(operation?.type)}
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid>Дата:</Grid>
            <Grid justifyContent="center" alignItems="center">
              {getFormattedDate(operation?.created_on)}
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid>Сумма:</Grid>
            <Grid justifyContent="center" alignItems="center">
              {get_friendly_token(
                operation?.count_token,
                operation?.token_name,
              )}{" "}
            </Grid>
          </Grid>
          {operation?.blockchain && (
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{
                maxWidth: "460px",
                maxHeight: "300px",
                overflow: "auto",
              }}
            >
              <Grid>Блокчейн:</Grid>
              <Box sx={{ fontFamily: "monospace", fontSize: "0.9rem" }}>
                <pre>{JSON.stringify(operation.blockchain, null, 2)}</pre>
              </Box>
            </Grid>
          )}
        </Box>
      </ModalContent>
    </Modal>
  );
}
