import { Grid } from "@mui/material";
import { useState } from "react";

export const NewsTable = () => {
  const [isMobile] = useState(window.innerWidth <= 768);

  return (
    <Grid container marginTop={"15px"}>
      Новостная страница
    </Grid>
  );
};
