import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, Typography } from "@mui/material";
import {
  DiamondOutlined,
  CurrencyBitcoinOutlined,
  AttachMoneyOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { useContext } from "react";
import { AuthContext, CurrencyContext, UserContext } from "../../context";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";

export default function Navbar() {
  const { currency, setCurrency } = useContext(CurrencyContext);
  const { user } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const { isAuth, setIsAuth } = useContext(AuthContext);

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const styleLink = {
    color: "black",
    border: "none",
    outline: "none",
    textDecoration: "none",
  };

  const logout = () => {
    localStorage.removeItem("auth");
    setIsAuth(false);
  };
  const DrawerList = (
    <Box
      sx={{ width: 250, height: "90%" }}
      role="presentation"
      onClick={toggleDrawer}
    >
      <Typography variant="h6" sx={{ mt: 2, ml: 2 }}>
        Меню
      </Typography>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        height="100%"
      >
        <Grid item>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" sx={{ ml: 2 }}>
              <Link style={styleLink} to="/market">
                Объявления
              </Link>
            </Typography>
            <Typography variant="body1" sx={{ ml: 2, mt: 0.6 }}>
              <Link style={styleLink} to="/deal">
                Сделки
              </Link>
            </Typography>
            <Typography variant="body1" sx={{ ml: 2, mt: 0.6 }}>
              <Link style={styleLink} to="/wallets">
                Кошельки
              </Link>
            </Typography>
            <Typography variant="body1" sx={{ ml: 2, mt: 0.6 }}>
              <Link style={styleLink} to="/account">
                Личный кабинет
              </Link>
            </Typography>
          </Box>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item sx={{ alignSelf: "center", textAlign: "center" }}>
            <PersonOutlineOutlined
              alt="Remy Sharp"
              src="https://mui.com/static/images/avatar/1.jpg"
              sx={{ width: 50, height: 50, margin: "auto" }}
            />
            <Grid container justifyContent="center">
              <Grid item>
                <Typography sx={{ width: "100%" }}>{user?.username} {user?.statistic}</Typography>
                <Typography onClick={logout}>Выйти</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid black",
      }}
    >
      <IconButton onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        {DrawerList}
      </Drawer>
      <div style={{ display: "flex", gap: "20px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Typography variant="body1">6560317,73</Typography>
          <CurrencyBitcoinOutlined />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Typography variant="body1">289,40</Typography>
          <DiamondOutlined />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Typography variant="body1">90,64</Typography>
          <AttachMoneyOutlined />
        </div>
      </div>
      <Select
        value={currency || ""}
        onChange={handleChange}
        autoWidth
        label="Валюта"
        variant="standard"
        style={{
          width: "80px",
          fontSize: "17px",
        }}
      >
        <MenuItem value={"RUB"}>RUB</MenuItem>
        <MenuItem value={"USD"}>USD</MenuItem>
        <MenuItem value={"UAH"}>UAH</MenuItem>
      </Select>
    </div>
  );
}
