import * as React from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  InputAdornment,
  TextField,
  Button,
  OutlinedInput,
  Chip,
  MenuItem,
  useTheme, CircularProgress
} from "@mui/material";

import { useContext, useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {
  get_friendly_token,
  get_nano_token,
  tokens,
  currency,
  payMethods,
  _timeToPay,
} from "../../../service/calc";
import { OrderAPI } from "../../../API/order";
import { uuidv4 } from "../../../service/utils";
import { NotificationContext } from "../../../context";
import { WalletAPI } from "../../../API/wallet";
import { SelectOne } from "../../UI/Select/SelectOne";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function CreateOrderForm({ handleClose, isSell }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { notification, setNotification } = useContext(NotificationContext);

  const [wallets, setWallets] = useState(null);

  const handleConfirm = () => {
    if (!tokenName) {
      setTokenError(true);
    }
    if (!currencyName) {
      setCurrencyName(true);
    }
    if (!amount) {
      setAmountError(true);
    }
    if (!minLimit) {
      setMinLimitError(true);
    }
    if (!selectedPayMethods || selectedPayMethods.length === 0) {
      setPayMethodsError(true);
    }
    // setIsLoading(true);
    const orderAPI = new OrderAPI();

    let orderData = {
      request_id: uuidv4(),
      token_name: tokenName,
      currency_name: currencyName,
      count_token: countTokenNano,
      amount: amount,
      min_limit: minLimit,
      time_pay_minutes: timeToPay,
      comment: comment,
      pay_methods: selectedPayMethods,
      type: isSell ? 'buy' : 'sell'
    };

    orderAPI
      .createOrder(orderData)
      .then((response) => {
        let notifData = {
          type: "notif",
          text: "Объявление отобразится в списке после заморозки токенов.",
          level: "info",
          title: "Объявление успешно создано!",
        };
        if (response.status === 200) {
          setIsLoading(false);
          setNotification(notifData);
          handleClose();
        } else if (response.status === 409) {
          setIsLoading(false);
          setNotification(notifData);
          handleClose();
        }
      })
      .catch((error) => {
        setNotification({
          type: "notif",
          text: "Объявление не создано! Попробуйте позже",
          level: "error",
          title: "Ошибка!",
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
        handleClose();
      });
  };

  useEffect(() => {
    if (!isSell) {
      const walletAPI = new WalletAPI();
      walletAPI
        .getWallets()
        .then((data) => {
          setWallets(data.data.wallets);
        })
        .catch((error) => console.error("Error:", error));
    }
  }, [isSell]);

  const [tokenName, setTokenName] = useState("TON");
  const [currencyName, setCurrencyName] = useState("RUB");
  const [countTokenNano, setCountTokenNano] = useState(0);
  const [countTokenFriendly, setCountTokenFriendly] = useState(3);
  const [minLimit, setMinLimit] = useState(100);
  const [timeToPay, setTimeToPay] = useState(15);
  const [amount, setAmount] = useState(0);
  const [comment, setComment] = useState("");

  const [tokenError, setTokenError] = useState(false);
  const [currencyError, setCurrencyError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [countTokenFriendlyError, setCountTokenFriendlyError] = useState(false);
  const [countTokenError, setCountTokenError] = useState(false);
  const [selectedPayMethods, setSelectedPayMethods] = React.useState([]);

  const [minLimitError, setMinLimitError] = useState(false);
  const [timeToPayError, setTimeToPayError] = useState(false);
  const [payMethodsError, setPayMethodsError] = useState(false);

  const [maxLimitTokenFriendly, setMaxLimitTokenFriendly] = React.useState(0);
  const [maxLimitToken, setMaxLimitToken] = React.useState(0);

  useEffect(() => {
    if (tokenName && wallets) {
      const wallet = wallets.find((wallet) => wallet.token_name === tokenName);
      if (wallet?.balance && wallet?.balance !== 0) {
        let max = get_friendly_token(wallet?.balance, wallet?.token_name);
        setMaxLimitTokenFriendly(max);
        setMaxLimitToken(wallet?.balance);
      } else setMaxLimitTokenFriendly(0);
    }
    let value = get_nano_token(countTokenFriendly, tokenName);
    setCountTokenNano(value);
  }, [wallets, tokenName, countTokenFriendly]);
  // useEffect(() => {
  //   if (countTokenNano && tokenName) {
  //     const friendly = get_friendly_token(countTokenNano, tokenName);
  //     const friendlyString = friendly.toFixed(12);
  //     const trimmedFriendly = parseFloat(friendlyString).toString();
  //     setCountTokenFriendly(trimmedFriendly);
  //     setCountTokenNano(countTokenNano);
  //   }
  // }, [countTokenNano, tokenName]);

  const handleChangeTokenName = (event) => {
    const {
      target: { value },
    } = event;
    let v = typeof value === "string" ? value.split(",") : value;
    setTokenName(v[0]);
    const allLimits = {
      TON: 3_000_000_000,
      BTC: 140_000,
      USDT: 1000,
    };
    const limit = allLimits[v[0]];
    setCountTokenFriendly(get_friendly_token(limit, v[0]));
  };
  const handleChangeCurrency = (event) => {
    const {
      target: { value },
    } = event;
    setCurrencyName(typeof value === "string" ? value : value);
  };
  const handleChangeTimeToPay = (event) => {
    const {
      target: { value },
    } = event;
    let v = typeof value === "string" ? value : value;
    setTimeToPay(v);
    setTimeToPayError(false);
  };
  const handleChangeMinLimit = (event) => {
    const {
      target: { value },
    } = event;
    setMinLimit(typeof value === "string" ? value : value);
    setMinLimitError(false);
  };
  const handleChangeAmount = (event) => {
    const {
      target: { value },
    } = event;
    setAmount(typeof value === "string" ? value : value);
    setAmountError(false);
  };
  const handleChangeComment = (event) => {
    const {
      target: { value },
    } = event;
    setComment(typeof value === "string" ? value : value);
  };

  const handleSelectedPayMethod = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPayMethods(typeof value === "string" ? value.split(",") : value);
    setPayMethodsError(false);
  };
  const handleCountTokenNanoChange = (event) => {
    const value = parseInt(event.target.value);
    console.log(value);
    if (!value || value > maxLimitToken) {
      setCountTokenError(true);
      return;
    }
    setCountTokenError(false);
    const friendlyValue = get_friendly_token(value, tokenName);
    setCountTokenNano(friendlyValue);
    setCountTokenFriendly(friendlyValue);
    setCountTokenError(false);
  };

  const handleCountTokenFriendlyChange = (event) => {
    const value = parseFloat(event.target.value);
    if (!isSell && (!value || value > maxLimitTokenFriendly)) {
      setCountTokenFriendlyError(true);
      return;
    }
    setCountTokenFriendlyError(false);
    const nanoValue = get_nano_token(value, tokenName);
    setCountTokenFriendly(value);
    setCountTokenNano(nanoValue.toFixed(0));
    setCountTokenFriendlyError(false);
  };

  const handleBuyAll = () => {
    const nanoValue = get_nano_token(maxLimitTokenFriendly, tokenName);
    setCountTokenFriendly(maxLimitTokenFriendly);
    setCountTokenNano(nanoValue.toFixed(0));
    setCountTokenError(false);
    setCountTokenFriendlyError(false);
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.32)",
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#1976d2" }} />
        </div>
      )}
      <Box sx={{ width: "100%", typography: "body1" }} overflow={"auto"}>
        Вы {isSell ? "покупаете" : "продаете"} {tokenName}
        <br />
        <br />
        <Grid container maxWidth={460}>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <SelectOne
                id="crypto_name"
                values={tokens}
                handleChange={handleChangeTokenName}
                selected={tokenName}
                selected_value={tokenName}
                title={"Токен"}
                required
                error={tokenError}
                errorText={tokenError && "Выберите токен"}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectOne
                id="currncy_name"
                values={currency}
                handleChange={handleChangeCurrency}
                selected={currencyName}
                title={"Валюта"}
                selected_value={currencyName}
                required
                error={currencyError}
                errorText={currencyError && "Выберите валюту"}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} marginTop={"10px"}>
            <Grid item xs={6}>
              <TextField
                id="amount"
                label="Цена за единицу"
                value={amount}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {currencyName}
                    </InputAdornment>
                  ),
                }}
                required
                error={amountError}
                helperText={amountError && "Введите корректную цену"}
                onChange={handleChangeAmount}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="amount_total"
                label="Всего"
                value={
                  isNaN(amount) || isNaN(parseFloat(countTokenFriendly))
                    ? ""
                    : amount * parseFloat(countTokenFriendly)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {currencyName}
                    </InputAdornment>
                  ),
                }}
                disabled
                error={amountError}
                helperText={amountError && "Введите корректную цену"}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} marginTop={"10px"}>
            <Grid item xs={6}>
              <TextField
                id="count_token_friendly"
                label="Кол-во токенов"
                value={countTokenFriendly}
                onChange={handleCountTokenFriendlyChange}
                type={"number"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{tokenName}</InputAdornment>
                  ),
                }}
                error={countTokenFriendlyError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="count_token_nano"
                label="Кол-во токенов (nano)"
                value={countTokenNano}
                onChange={handleCountTokenNanoChange}
                type={"number"}
                disabled
                required
                error={countTokenError}
                helperText={
                  amountError && "Введите корректное количество токенов"
                }
              />
            </Grid>
          </Grid>

          {!isSell && (
            <Button
              onClick={handleBuyAll}
              sx={{
                fontSize: "10px",
                padding: 0,
                margin: 0,
                width: "100%",
                textAlign: "left",
                justifyContent: "flex-start",
              }}
            >
              Продать все: {maxLimitTokenFriendly} {tokenName}
            </Button>
          )}

          <Grid container item xs={12} marginTop={"10px"}>
            <Grid item xs={6} marginTop={"10px"}>
              <TextField
                id="minLimit"
                label="Минимум для сделки"
                value={minLimit}
                onChange={handleChangeMinLimit}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {currencyName}
                    </InputAdornment>
                  ),
                }}
                required
                error={minLimitError}
                helperText={
                  minLimitError && "Введите корректный минимум для сделки"
                }
              />
            </Grid>
            <Grid item xs={6} marginTop={"10px"}>
              <SelectOne
                id="timeTopay"
                values={_timeToPay}
                handleChange={handleChangeTimeToPay}
                selected={timeToPay}
                selected_value={timeToPay}
                title={"Время на оплату"}
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={12} marginTop={"10px"}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-chip-label">
                Методы оплаты
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={selectedPayMethods}
                onChange={handleSelectedPayMethod}
                input={
                  <OutlinedInput id="select-multiple-chip" label="payMethods" />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                error={payMethodsError}
                MenuProps={MenuProps}
              >
                {payMethods.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, selectedPayMethods, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="comment"
              value={comment}
              label="Комментарий"
              rows={3}
              variant="standard"
              fullWidth
              multiline
              onChange={handleChangeComment}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          mt: 2,
          marginBottom: "5%",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          sx={{ mr: 2 }}
          onClick={handleConfirm}
        >
          Создать
        </Button>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Отмена
        </Button>
      </Box>
    </div>
  );
}
