import * as React from "react";
import Box from "@mui/material/Box";
import { Modal, ModalContent, StyledBackdrop } from "../baseModal";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { Grid, Tooltip, Typography } from "@mui/material";

export function WalletUpModal({ open, handleClose, wallet }) {
  const [tonURI, setTonURI] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (wallet?.address) {
      const tonAddress = wallet.address;
      const tonUri = `ton:${tonAddress}`;
      setTonURI(tonUri);
    }
  }, [wallet]);

  // Функция для копирования адреса в буфер обмена
  const copyToClipboard = () => {
    navigator.clipboard.writeText(wallet?.address);
    setCopied(true);

    // Сброс сообщения "Скопирован" через 1 секунду
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <Modal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      slots={{ backdrop: StyledBackdrop }}
      m_top_xs="0%"
      m_top_xl="5%"
    >
      <ModalContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
          maxWidth: "450px",
        }}
        sizeXS="100%"
        sizeXL="60vh"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
            zIndex: 999,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ width: "100%", marginTop: "50px" }} overflow={"auto"}>
          <Grid container direction="column" alignItems="center" spacing={1}>
            {wallet?.address !== "0" ? (
              <>
                <Grid item>
                  <QRCode value={tonURI} size={200} />
                </Grid>
                <Grid item>
                  {/* Используем Tooltip для отображения адреса с троеточием, если он слишком длинный */}
                  <Tooltip title={wallet?.address} placement="top">
                    <Typography
                      onClick={copyToClipboard} // Добавляем обработчик для копирования по клику
                      style={{
                        cursor: "pointer",
                        maxWidth: "300px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        display: "block",
                        textAlign: "center", // Центрируем текст
                        margin: "0 auto", // Центрируем элемент по горизонтали
                      }}
                    >
                      {copied ? "Скопирован" : wallet?.address}
                    </Typography>
                  </Tooltip>
                </Grid>
              </>
            ) : (
              <div style={{ margin: "25px" }}>
                Кошелек в данный момент не обслуживается!
              </div>
            )}
          </Grid>
          {wallet?.address !== "0" && (
            <Grid margin="10px">
              <Typography>
                Будьте внимательны при указании адреса зачисления! <br />
                <br />
                Сроки зачисления: <br />
                TON - до 10 минут <br />
                USDT - до 15 минут <br />
                BTC - до 12 часов <br />
                <br />
                Для зачисления BTC, необходимо 3 подтверждения сети. Обычно это
                занимает от 15 минут до 12 часов, в зависимости от уровня
                комиссии при переводе.
                <br />
                <br />
                <br />
                Если по истечению указанных сроков, не монеты не поступили на
                счет, свяжитесь с поддержкой в личном кабинете
              </Typography>
            </Grid>
          )}
        </Box>
      </ModalContent>
    </Modal>
  );
}
