import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";

export const DealButtons = ({
  sellerText,
  buyerText,
  user_is_seller,
  handleClose,
  buyerDisabled,
  sellerDisabled,
  handleClick,

}) => {
  // Функция для создания пары кнопок в зависимости от роли пользователя
  const renderButtonPair = (sellerText, buyerText) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          mt: 2,
          marginBottom: "5%",
        }}
      >
        {user_is_seller ? (
          <>
            <Button
              color="primary"
              variant="contained"
              sx={{ mr: 2 }}
              disabled={sellerDisabled}
              onClick={handleClick}
            >
              {sellerText}
            </Button>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Отмена
            </Button>
          </>
        ) : (
          <>
            <Button
              color="primary"
              variant="contained"
              sx={{ mr: 2 }}
              disabled={buyerDisabled}
              onClick={handleClick}
            >
              {buyerText}
            </Button>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Отмена
            </Button>
          </>
        )}
      </Box>
    );
  };

  // Возвращаем компоненты с кнопками
  return renderButtonPair(sellerText, buyerText);
};
