import { BaseAPI } from "./base";
import { getAuthToken } from "../service/auth";

const baseUrl = "https://wallet.pyostr.ru/api/v1";

export class WalletAPI extends BaseAPI {
  constructor() {
    super(baseUrl);
  }

  async getWallets() {
    let headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };
    return this.get("wallet/getUserWallets", null, headers);
  }
}
