import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { WalletAPI } from "../../../API/wallet";
import { WalletBlock } from "../../Blocks/Wallet/WalletBlock";

export function WalletsTable() {
  const [wallets, setWallets] = useState([]);

  useEffect(() => {
    const walletAPI = new WalletAPI();
    walletAPI
      .getWallets()
      .then((data) => {
        setWallets(data.data.wallets);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <Grid container item spacing={1} style={{ maxWidth: "400px" }}>
      {wallets.map((wallet) => (
        <WalletBlock key={wallet.token_name} wallet={wallet} />
      ))}
    </Grid>
  );
}
