import axios from "axios";

export class BaseAPI {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async get(endpoint, data, headers) {
    try {
      const queryString = new URLSearchParams(data).toString();
      return await axios.get(`${this.baseUrl}/${endpoint}?${queryString}`, {
        headers: headers,
      });
    } catch (error) {
      return this.handleError(error);
    }
  }

  async post(endpoint, data, headers) {
    try {
      return await axios.post(`${this.baseUrl}/${endpoint}`, data, {
        headers: headers,
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  async put(endpoint, data, headers) {
    try {
      return await axios.put(`${this.baseUrl}/${endpoint}`, data, {
        headers: headers,
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  async delete(endpoint) {
    try {
      const response = await axios.delete(`${this.baseUrl}/${endpoint}`);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError(error) {
    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
    } else if (error.request) {
      console.error("Error:", error.request);
    } else {
      console.error("Error:", error.message);
    }
    return error;
  }
}
