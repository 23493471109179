import React, { useContext, useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  AccountBalanceWalletOutlined,
  AddCircleOutlineOutlined,
  ArticleOutlined,
  AssessmentOutlined,
  CurrencyExchangeOutlined,
  ExitToAppOutlined,
  FiberNewOutlined,
  HttpsOutlined,
  NotificationsActiveOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../context";

export const BottomNav = () => {
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Состояние для открытия/закрытия бокового меню
  const { isAuth, setIsAuth } = useContext(AuthContext);

  const navItems = [
    { label: "Сделки", icon: <AddCircleOutlineOutlined />, path: "/deal" },
    { label: "Маркет", icon: <CurrencyExchangeOutlined />, path: "/market" },
    {
      label: "Кошельки",
      icon: <AccountBalanceWalletOutlined />,
      path: "/wallets",
    },
    { label: "ЛК", icon: <PersonOutlineOutlined />, path: "/account" },
  ];

  const navItemsSide = [
    {
      label: "Безопасность",
      icon: <HttpsOutlined />,
      path: "/account?tab=secure",
    },
    {
      label: "Уведомления",
      icon: <NotificationsActiveOutlined />,
      path: "/account?tab=notification",
    },
    {
      label: "Статистика",
      icon: <AssessmentOutlined />,
      path: "/account?tab=total",
    },
    {
      label: "Новости",
      icon: <ArticleOutlined />,
      path: "/account?tab=news",
    },
  ];

  const isSuperSmall = window.innerWidth <= 340;

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleLinkClick = (event, itemPath) => {
    // Если нажата кнопка "ЛК", открываем боковое меню
    if (itemPath === "/account") {
      event.preventDefault(); // Отменяем стандартное действие (переход по ссылке)
      handleDrawerOpen(); // Открываем боковое меню
    }
  };

  const logout = () => {
    localStorage.removeItem("auth");
    setIsAuth(false);
  };

  return (
    <>
      <BottomNavigation
        value={location.pathname}
        onChange={(event, newValue) => {}}
        showLabels
        sx={{
          width: "100%",
          position: isSuperSmall ? "absolute" : "fixed",
          bottom: 0,
          backgroundColor: "#ebebeb",
          zIndex: 1000,
        }}
      >
        {navItems.map((item, index) => (
          <BottomNavigationAction
            key={index}
            label={item.label}
            icon={item.icon}
            component={Link}
            to={item.path}
            value={item.path}
            sx={{
              color:
                location.pathname === item.path
                  ? "text.primary"
                  : "text.secondary",
              minWidth: "unset",
            }}
            onClick={(event) => handleLinkClick(event, item.path)}
          />
        ))}
      </BottomNavigation>

      {/* Боковое меню (Drawer) */}
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
        <div
          sx={{ width: 250 }}
          role="presentation"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          <List>
            {navItemsSide.map((item, index) => (
              <ListItem
                key={index}
                component={Link}
                to={item.path}
                sx={{
                  color: "text.primary",
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
            <ListItem button onClick={() => logout()}>
              <ListItemIcon>
                <ExitToAppOutlined />
              </ListItemIcon>
              <ListItemText primary="Выйти" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
};
