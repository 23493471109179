import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { SessionsBlock } from "../../Blocks/Account/SessionBlock";

const sessionsData = [
  {
    session_id: "yui123y78dfjkh",
    created_on: "2024-02-20T05:14:02",
    source: "Android / Chrome",
    city: "Москва",
    IP: "123.53.163.242",
  },
  {
    session_id: "yui123фвыy78dfjkh",
    created_on: "2024-02-20T05:14:02",
    source: "Android / Chrome",
    city: "Москва",
    IP: "55.276.211.59",
  },
  {
    session_id: "yui123y78dfjвфыkh",
    created_on: "2024-02-20T05:14:02",
    source: "Android / Chrome",
    city: "Санкт-Петербург",
    IP: "255.181.13.45",
  },
];
export const SessionTable = () => {
  const [sessions, setSessions] = useState(sessionsData);
  return (
    <Grid container xs={12} item>
      <Grid item xs={12} borderBottom="1px solid black">
        Сеансы
      </Grid>
      <Grid container item xs={12}>
        {sessions?.map((session) => (
          <SessionsBlock key={session.session_id} session={session} />
        ))}
      </Grid>
    </Grid>
  );
};
