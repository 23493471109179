import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField, Button, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IosShareOutlined, SendOutlined } from "@mui/icons-material";
import { getAuthToken } from "../../../service/auth";

const Message = ({ message, from_user }) => {
  const renderFile = () => {
    if (message.file && message.file_type) {
      if (message.file_type === "jpeg") {
        return (
          <img
            src={message.file}
            alt="Фото"
            style={{ maxWidth: "100%", borderRadius: "5px" }}
          />
        );
      } else if (message.file_type === "mp4") {
        return (
          <video controls style={{ maxWidth: "100%", borderRadius: "5px" }}>
            <source src={message.file} type="video/mp4" />
            Ваш браузер не поддерживает видео.
          </video>
        );
      } else if (message.file_type === "pdf") {
        return (
          <embed
            src={message.file}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        );
      }
    }
    return null;
  };

  return (
    <Grid
      container
      justifyContent={from_user ? "flex-end" : "flex-start"}
      style={{ marginBottom: "10px" }}
    >
      <Grid
        item
        xs={8}
        style={{
          border: from_user ? "1px solid #c5c5c5" : "1px solid #EAEAEA",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        {message.text && (
          <Typography
            variant="body1"
            style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}
          >
            {message.text}
          </Typography>
        )}
        {renderFile()}
      </Grid>
    </Grid>
  );
};

export const ChatDealBlock = ({ deal_id }) => {
  const [messageText, setMessageText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const socketRef = useRef(null);
  const isUnmounted = useRef(false);

  const connectSocket = () => {
    socketRef.current = new WebSocket(
      "wss://chats.pyostr.ru/api/v1/ws/deal/" + deal_id + "/",
    );
    const socket = socketRef.current;

    socket.onopen = () => {
      console.log(`Соединение с сокетом ЧАТОВ установлено`);
      socket.send(
        JSON.stringify({
          event: "open",
          token: getAuthToken(),
          deal_id: deal_id,
          message: null,
        }),
      );
    };

    socket.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      if (eventData.event === "history") {
        console.log("Получены данные истории:", eventData.data);
        setMessages(eventData.data);
        // Добавить логику для обработки полученных данных истории
      } else if (eventData.event === "update") {
        console.log("Получены данные обновления:", eventData.data);
        const newMessage = eventData.data;
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    };

    socket.onclose = () => {
      if (!isUnmounted.current) {
        console.log("Закрыто чаты, пытаюсь переподключиться...");
        setTimeout(() => {
          connectSocket(); // Попытка переподключения
        }, 5000);
      } else {
        console.log("Компонент размонтирован, закрытие сокета");
        socket.close();
      }
    };

    return () => {
      console.log("Unmounting ChatDealBlock, closing socket");
      isUnmounted.current = true;
      socket.close();
    };
  };

  useEffect(() => {
    connectSocket();

    return () => {
      console.log("Cleaning up ChatDealBlock, closing socket");
      isUnmounted.current = true;
      socketRef.current.close();
    };
  }, [connectSocket, deal_id]);

  const handleMessageChange = (event) => {
    setMessageText(event.target.value);
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFileName(file.name); // Устанавливаем название выбранного файла
  };

  const handleFileAttachment = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*, application/pdf"; // Укажите нужные типы файлов
    fileInput.onchange = handleFileSelect;
    fileInput.click();
  };

  const handleCancelFile = () => {
    setSelectedFile(null);
    setFileName("");
  };

  const sendMessage = () => {
    // Here you can send the message to the server using the socket or any other method
    // You can also handle the file upload here if needed
    console.log("Sending message:", messageText);
    console.log("Selected file:", selectedFile);
    const inputMessageData = {
      deal_id: deal_id,
      event: "new_message",
      message: {
        text: messageText,
        images: null,
      },
      token: getAuthToken(),
    };
    // Преобразуем объект в строку JSON
    const messageToSend = JSON.stringify(inputMessageData);

    // Отправляем сообщение в сокет
    socketRef.current.send(messageToSend);
    setMessageText("");
    setSelectedFile(null);
    setFileName("");
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto", block: "end" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <Box
        sx={{ width: "100%", typography: "body1", height: "100%" }}
        overflow={"auto"}
      >
        <Grid
          container
          spacing={1}
          style={{ flex: "1 1 auto", height: "100%" }}
        >
          <Grid item xs={12}>
            {messages?.map((message, index) => (
              <Message
                key={index}
                message={message}
                from_user={message?.from_user}
              />
            ))}
            <div ref={messagesEndRef} />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          mt: 2,
          marginBottom: "5%",
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={8}>
            {selectedFile && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" style={{ marginRight: "5px" }}>
                  {fileName}
                </Typography>
                <Button onClick={handleCancelFile}>
                  <CloseIcon />
                </Button>
              </div>
            )}
            <TextField
              id="comment"
              label="Введите сообщение"
              rows={3}
              variant="standard"
              fullWidth
              multiline
              value={messageText}
              onChange={handleMessageChange}
            />
          </Grid>

          <Grid item xs={2}>
            <Button onClick={handleFileAttachment} className="attach-button">
              <IosShareOutlined />
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={sendMessage}>
              <SendOutlined width={35} />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
