import { BaseAPI } from "./base";
import { getAuthToken } from "../service/auth";

const baseUrl = "https://order.pyostr.ru/api/v1";
// const baseUrl = "http://172.17.0.1:8006/api/v1";

export class OrderAPI extends BaseAPI {
  constructor() {
    super(baseUrl);
  }

  async getOrders(data) {
    return this.get("orders/getOrders", data);
  }
  async getUserOrders(data) {
    let headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };
    return this.get("orders/getUserOrder", data, headers);
  }

  async getOrder(data) {
    let headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };
    return this.get("orders/getOrder", data, headers);
  }

  async createOrder(data) {
    let headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };
    return this.post(`orders/createOrder`, data, headers);
  }
}
