import { Button, Grid } from "@mui/material";
import { get_friendly_token } from "../../../service/calc";
import { useState } from "react";
import { getFormattedDate } from "../../../service/date";
import { DealModal } from "../../Modals/DealModal";

export const DealBlock = ({ deal }) => {
  const [openCreateDealModal, setCreateDealModalOpen] = useState(false);

  const handleOpen = () => {
    setCreateDealModalOpen(true);
  };

  const handleClose = () => {
    setCreateDealModalOpen(false);
  };

  return (
    <Grid
      border="1px solid black"
      height={110}
      margin={{ xs: 0, sm: 1, md: 1, lg: 1, xl: 1 }}
      marginTop={{ xs: 0.5, sm: 1, md: 1, lg: 1, xl: 1 }}
      display="block"
      padding={0.5}
      item
      xs={12}
      sm={5.5}
      md={3.5}
      lg={5.5}
      xl={3.5}
    >
      <DealModal
        deal={deal}
        handleClose={handleClose}
        open={openCreateDealModal}
      />
      <Grid
        container
        item
        xs={12}
        height={25}
        borderBottom="1px solid black"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={9}>
          {deal.user_is_seller ? "Покупка" : "Продажа"}{" "}
          {get_friendly_token(deal.count_token, deal.token_name)}{" "}
          {deal.token_name}
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="flex-end">
          <Button style={{ padding: 0, margin: 0 }} onClick={handleOpen}>
            Открыть
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid>Сумма:</Grid>
        <Grid justifyContent="center" alignItems="center">
          {deal.amount * get_friendly_token(deal.count_token, deal.token_name)}{" "}
          {deal.currency_name}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid>Оплата:</Grid>
        <Grid justifyContent="center" alignItems="center">
          {deal.pay_method}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid>Статус:</Grid>
        <Grid justifyContent="center" alignItems="center">
          {deal.status}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid>Создано:</Grid>
        <Grid justifyContent="center" alignItems="center">
          {getFormattedDate(deal?.created_on)}
        </Grid>
      </Grid>
    </Grid>
  );
};
