import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import { WalletHistoryBlock } from "../../Blocks/Wallet/WalletHistoryBlock";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { WalletHistoryMobileBlock } from "../../Blocks/Wallet/WalletHistoryMobileBlock";

const walletsHistory = [
  {
    request_id: "48a1b03e-f602-413c-8860-79c688be150",
    token_name: "TON",
    type: "in",
    count_token: "323823583",
    created_on: "2024-02-20T05:14:02",
    source: "0QDdLX_1I8Kyo4wuOIgJKw7rp7eMfLvh5Piucjd3WWCmxb7k",
    to: null,
    blockchain: {
      fee: "100290",
      data: "te6cckECBwEAAbcAA7V90tf/UjwrKjjC44iAkrDuunt4x8u+Hk+K5yN3dZYKbFAAARe17pVsS0Zy4Tln4j1jmyi4sUhOg1ViDjudOiXlcJrumAUkYI1AAAEXrZsfIBZdNETAABRgMPhIAQIDAQGgBACCcmOID+NksQre3uvVYWfaE9RmwrCeUH7j0us/mctIyfpbQwyxi6D5yc1gIWIZItxX6ytPxDUUrfKZVNxFLivQefgCGwyASIlASoF8gBhgMNQRBQYA90gBJCJaJssVOKyQUQXQkN+RGCUcOXtCYTEVqc1NoQTm+P0AN0tf/UjwrKjjC44iAkrDuunt4x8u+Hk+K5yN3dZYKbFUBKgXyAAGFFhgAAAi9r3SrYbLpoiYAAAAADQ6Ojg5nReXuhc2spe6Mrm6M7S7Mrkvuje3L7E3ukAAnCfMPQkAAAAAAAAAAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABbwAAAAAAAAAAAAAAAAS1FLaRJ5QuM990nhh8UYSKv4bVGu4tw/IIW8MYUE5+OBJajUAU=",
      utime: 1708344396,
      in_msg: {
        value: "5000000000",
        source: "EQCSES0TZYqcVkgoguhIb8iMEo4cvaEwmIrU5qbQgnN8fmvP",
        fwd_fee: "666672",
        ihr_fee: "0",
        message: "https://t.me/testgiver_ton_bot",
        msg_data: { text: "aHR0cHM6Ly90Lm1lL3Rlc3RnaXZlcl90b25fYm90" },
        body_hash: "CMMIRQMWLHXJjH1x5SzZUOdTkBKcBkGwThU2UacDJt4=",
        created_lt: "19221571000003",
        destination: "EQDdLX_1I8Kyo4wuOIgJKw7rp7eMfLvh5Piucjd3WWCmxVir",
      },
      address: {
        mnemonic: null,
        account_address: "EQDdLX_1I8Kyo4wuOIgJKw7rp7eMfLvh5Piucjd3WWCmxVir",
      },
      out_msgs: [],
      other_fee: "100000",
      storage_fee: "290",
      transaction_id: {
        lt: "19221571000004",
        hash: "NJv2S4LHlHReNdFJ9ysstHNzB7nsX0adqGE3eWvKslk=",
      },
    },
  },
  {
    request_id: "2f7e64b7-6c0c-4380-9d7e-ad3671b37",
    token_name: "TON",
    type: "out",
    count_token: "544323823583",
    created_on: "2024-02-20T05:14:02",
    source: null,
    to: "0QDdLX_1I8Kyo4wuOIgJKw7rp7eMfLvh5Piucjd3WWCmxb7k",
    blockchain: {
      fee: "100290",
      data: "te6cckECBwEAAbcAA7V90tf/UjwrKjjC44iAkrDuunt4x8u+Hk+K5yN3dZYKbFAAARe17pVsS0Zy4Tln4j1jmyi4sUhOg1ViDjudOiXlcJrumAUkYI1AAAEXrZsfIBZdNETAABRgMPhIAQIDAQGgBACCcmOID+NksQre3uvVYWfaE9RmwrCeUH7j0us/mctIyfpbQwyxi6D5yc1gIWIZItxX6ytPxDUUrfKZVNxFLivQefgCGwyASIlASoF8gBhgMNQRBQYA90gBJCJaJssVOKyQUQXQkN+RGCUcOXtCYTEVqc1NoQTm+P0AN0tf/UjwrKjjC44iAkrDuunt4x8u+Hk+K5yN3dZYKbFUBKgXyAAGFFhgAAAi9r3SrYbLpoiYAAAAADQ6Ojg5nReXuhc2spe6Mrm6M7S7Mrkvuje3L7E3ukAAnCfMPQkAAAAAAAAAAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABbwAAAAAAAAAAAAAAAAS1FLaRJ5QuM990nhh8UYSKv4bVGu4tw/IIW8MYUE5+OBJajUAU=",
      utime: 1708344396,
      in_msg: {
        value: "5000000000",
        source: "EQCSES0TZYqcVkgoguhIb8iMEo4cvaEwmIrU5qbQgnN8fmvP",
        fwd_fee: "666672",
        ihr_fee: "0",
        message: "https://t.me/testgiver_ton_bot",
        msg_data: { text: "aHR0cHM6Ly90Lm1lL3Rlc3RnaXZlcl90b25fYm90" },
        body_hash: "CMMIRQMWLHXJjH1x5SzZUOdTkBKcBkGwThU2UacDJt4=",
        created_lt: "19221571000003",
        destination: "EQDdLX_1I8Kyo4wuOIgJKw7rp7eMfLvh5Piucjd3WWCmxVir",
      },
      address: {
        mnemonic: null,
        account_address: "EQDdLX_1I8Kyo4wuOIgJKw7rp7eMfLvh5Piucjd3WWCmxVir",
      },
      out_msgs: [],
      other_fee: "100000",
      storage_fee: "290",
      transaction_id: {
        lt: "19221571000004",
        hash: "NJv2S4LHlHReNdFJ9ysstHNzB7nsX0adqGE3eWvKslk=",
      },
    },
  },
  {
    request_id: "4fa893b3c-c807-4c1e-893d-223cb7674f",
    token_name: "TON",
    type: "in",
    count_token: "234323823583",
    created_on: "2024-02-20T05:14:02",
    source: "0QDdLX_1I8Kyo4wuOIgJKw7rp7eMfLvh5Piucjd3WWCmxb7k",
    to: null,
    blockchain: {
      fee: "100290",
      data: "te6cckECBwEAAbcAA7V90tf/UjwrKjjC44iAkrDuunt4x8u+Hk+K5yN3dZYKbFAAARe17pVsS0Zy4Tln4j1jmyi4sUhOg1ViDjudOiXlcJrumAUkYI1AAAEXrZsfIBZdNETAABRgMPhIAQIDAQGgBACCcmOID+NksQre3uvVYWfaE9RmwrCeUH7j0us/mctIyfpbQwyxi6D5yc1gIWIZItxX6ytPxDUUrfKZVNxFLivQefgCGwyASIlASoF8gBhgMNQRBQYA90gBJCJaJssVOKyQUQXQkN+RGCUcOXtCYTEVqc1NoQTm+P0AN0tf/UjwrKjjC44iAkrDuunt4x8u+Hk+K5yN3dZYKbFUBKgXyAAGFFhgAAAi9r3SrYbLpoiYAAAAADQ6Ojg5nReXuhc2spe6Mrm6M7S7Mrkvuje3L7E3ukAAnCfMPQkAAAAAAAAAAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABbwAAAAAAAAAAAAAAAAS1FLaRJ5QuM990nhh8UYSKv4bVGu4tw/IIW8MYUE5+OBJajUAU=",
      utime: 1708344396,
      in_msg: {
        value: "5000000000",
        source: "EQCSES0TZYqcVkgoguhIb8iMEo4cvaEwmIrU5qbQgnN8fmvP",
        fwd_fee: "666672",
        ihr_fee: "0",
        message: "https://t.me/testgiver_ton_bot",
        msg_data: { text: "aHR0cHM6Ly90Lm1lL3Rlc3RnaXZlcl90b25fYm90" },
        body_hash: "CMMIRQMWLHXJjH1x5SzZUOdTkBKcBkGwThU2UacDJt4=",
        created_lt: "19221571000003",
        destination: "EQDdLX_1I8Kyo4wuOIgJKw7rp7eMfLvh5Piucjd3WWCmxVir",
      },
      address: {
        mnemonic: null,
        account_address: "EQDdLX_1I8Kyo4wuOIgJKw7rp7eMfLvh5Piucjd3WWCmxVir",
      },
      out_msgs: [],
      other_fee: "100000",
      storage_fee: "290",
      transaction_id: {
        lt: "19221571000004",
        hash: "NJv2S4LHlHReNdFJ9ysstHNzB7nsX0adqGE3eWvKslk=",
      },
    },
  },
  {
    request_id: "4fa892b3c-c807-4c1e-893d-223cb7674f",
    token_name: "TON",
    type: "from_ESCROW",
    count_token: "234323823583",
    created_on: "2024-02-20T05:14:02",
    source: "ESCROW",
    to: null,
  },
  {
    request_id: "4f1a89b3c-c807-4c1e-893d-223cb7674f",
    token_name: "TON",
    type: "to_ESCROW",
    count_token: "234323823583",
    created_on: "2024-02-20T05:14:02",
    source: null,
    to: "ESCROW",
  },
  {
    request_id: "4fa892b3c-8c807-4c1e-893d-223cb7674f",
    token_name: "TON",
    type: "from_ESCROW",
    count_token: "234323823583",
    created_on: "2024-02-20T05:14:02",
    source: "ESCROW",
    to: null,
  },
  {
    request_id: "4f1a89b3c-c8507-4c1e-893d-223cb7674f",
    token_name: "TON",
    type: "to_ESCROW",
    count_token: "234323823583",
    created_on: "2024-02-20T05:14:02",
    source: null,
    to: "ESCROW",
  },
  {
    request_id: "4fa892b3c-c4807-4c1e-893d-223cb7674f",
    token_name: "TON",
    type: "from_ESCROW",
    count_token: "234323823583",
    created_on: "2024-02-20T05:14:02",
    source: "ESCROW",
    to: null,
  },
  {
    request_id: "4f1a89b3c-c2807-4c1e-893d-223cb7674f",
    token_name: "BTC",
    type: "to_ESCROW",
    count_token: "234323823583",
    created_on: "2024-02-20T05:14:02",
    source: null,
    to: "ESCROW",
  },
];
export const WalletHistoryTable = () => {
  const [wallets, setWallets] = useState([]);
  const [filteredWallets, setFilteredWallets] = useState([]);
  const [selectedType, setSelectedType] = useState("in");
  const [selectedToken, setSelectedToken] = useState("TON");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    setWallets(walletsHistory);
    setFilteredWallets(walletsHistory);
  }, []);

  const handleChangeToken = (event) => {
    const value = event.target.value;
    setSelectedToken(value);
    if (!value) {
      setFilteredWallets(wallets);
      return;
    }
    const filtered = wallets.filter((order) =>
      order.token_name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredWallets(filtered);
  };

  const handleChangeType = (event) => {
    const value = event.target.value;
    setSelectedType(value);
    if (!value) {
      setFilteredWallets(wallets);
      return;
    }
    const filtered = wallets.filter((order) =>
      order.type.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredWallets(filtered);
  };

  const handleChangeDate = (event) => {
    const value = event.target.value;
    setSelectedDate(value);
    const filtered = wallets.filter(
      (order) =>
        new Date(order.created_on).toLocaleDateString() ===
        new Date(value).toLocaleDateString(),
    );
    setFilteredWallets(filtered);
  };

  // Calculate the indexes for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredWallets.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Grid item xs={12} padding={0} margin={0} marginBottom={1} minHeight={600}>
      <Grid container item xs={12} spacing={1} marginTop={0}>
        <Grid
          container
          item
          xs={12}
          spacing={1}
          style={{
            display: "flex",
            flexWrap: "wrap",
            overflowX: "auto",
            paddingBottom: "8px",
            paddingTop: "0",
          }}
        >
          <Grid item md={2} textAlign="center">
            <FormControl variant="standard" sx={{ minWidth: 100 }}>
              <InputLabel>Тип</InputLabel>
              <Select
                value={selectedType}
                onChange={handleChangeType}
                label="Type"
              >
                <MenuItem value="in">Зачисление</MenuItem>
                <MenuItem value="out">Исходящая</MenuItem>
                <MenuItem value="from_ESCROW">Заморозка</MenuItem>
                <MenuItem value="to_ESCROW">Разморозка</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} textAlign="center">
            <FormControl variant="standard" sx={{ minWidth: 100 }}>
              <InputLabel>Токен</InputLabel>
              <Select
                value={selectedToken}
                onChange={handleChangeToken}
                label="Token"
              >
                <MenuItem value="TON">TON</MenuItem>
                <MenuItem value="BTC">BTC</MenuItem>
                <MenuItem value="USDT">USDT</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} textAlign="center">
            <FormControl variant="standard" sx={{ minWidth: 100 }}>
              <TextField
                type="date"
                label="Дата"
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedDate || ""}
                onChange={handleChangeDate}
              />
            </FormControl>
          </Grid>
        </Grid>
        {isMobile
          ? currentItems.map((operation) => (
              <WalletHistoryMobileBlock
                key={operation?.request_id}
                operation={operation}
              />
            ))
          : currentItems.map((operation) => (
              <WalletHistoryBlock
                key={operation?.request_id}
                operation={operation}
              />
            ))}
        <Grid container item xs={12} justifyContent="center">
          <Pagination
            count={Math.ceil(filteredWallets.length / itemsPerPage)}
            page={currentPage}
            onChange={(event, page) => paginate(page)}
            variant="outlined"
            shape="rounded"
            size="large"
            color="primary"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
