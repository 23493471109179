import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "14px",
  height: "24px",
}));

export const SessionsBlock = ({ session }) => {
  const [isMobile] = useState(window.innerWidth <= 768);

  return isMobile ? (
    <Grid container item marginTop="15px" border="1px solid black">
      <Grid item xs={8}>
        <Item>{session?.source}</Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
          <Button>Выйти</Button>
        </Item>
      </Grid>
      <Grid item xs={5}>
        <Item>{session?.created_on}</Item>
      </Grid>
      <Grid item xs={7}>
        <Item>{session?.IP}</Item>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={1} marginTop="5px">
      <Grid item xs={2}>
        <Item>{session?.IP}</Item>
      </Grid>
      <Grid item xs={3}>
        <Item>{session?.source}</Item>
      </Grid>
      <Grid item xs={3}>
        <Item>{session?.city}</Item>
      </Grid>
      <Grid item xs={3}>
        <Item>{session?.created_on}</Item>
      </Grid>
      <Grid item xs={1}>
        <Button>Выйти</Button>
      </Grid>
    </Grid>
  );
};

SessionsBlock.propTypes = {
  sessions: PropTypes.shape({
    session_id: PropTypes.string,
    created_on: PropTypes.string,
    source: PropTypes.string,
    city: PropTypes.string,
    IP: PropTypes.string,
  }),
};
