import { Button, Grid } from "@mui/material";
import { get_friendly_token } from "../../../service/calc";
import { useState } from "react";
import { getFormattedDate } from "../../../service/date";
import { WalletHistoryModal } from "../../Modals/WalletHistoryModal";

export const WalletHistoryBlock = ({ operation }) => {
  const [openHistoryModal, setHistoryModalOpen] = useState(false);

  const handleOpen = () => {
    setHistoryModalOpen(true);
  };

  const handleClose = () => {
    setHistoryModalOpen(false);
  };

  const getType = (type) => {
    switch (type) {
      case "in":
        return "Зачисление";
      case "out":
        return "Исходящая";
      case "from_ESCROW":
        return "Разморозка";
      case "to_ESCROW":
        return "Заморозка";
      default:
        return "";
    }
  };

  return (
    <Grid
      border="1px solid black"
      height={70}
      xs={12}
      margin={{ xs: 0, sm: 0, md: 1, lg: 1, xl: 1 }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      item
      padding={0}
      style={{ padding: "0 0 0" }}
    >
      <WalletHistoryModal
        operation={operation}
        handleClose={handleClose}
        open={openHistoryModal}
      />

      <Grid
        container
        item
        xs={12}
        // justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={2} textAlign="center">
          {getType(operation?.type)}
        </Grid>
        <Grid item xs={2} textAlign="center">
          {operation?.token_name}
        </Grid>
        <Grid item xs={3} textAlign="center">
          {getFormattedDate(operation?.created_on)}
        </Grid>
        <Grid item xs={2.5} textAlign="center">
          {get_friendly_token(operation?.count_token, operation?.token_name)}{" "}
        </Grid>
        <Grid item xs={2} textAlign="center">
          <Button style={{ padding: 0, margin: 0 }} onClick={handleOpen}>
            Открыть
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
