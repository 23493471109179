import { Button, Grid } from "@mui/material";
import { get_friendly_token, get_max_limit } from "../../../service/calc";
import { useState } from "react";
import { CreateDealModal } from "../../Modals/CreateDealModal";
import { EditOrderForm } from "./EditOrderForm";

export const UserOrderBlock = ({ order }) => {
  const [openEditOrderModal, setOpenEditOrderModal] = useState(false);

  const handleActiveOrder = () => {
    alert('Деактивация не доступна')
  };

  const handleOpenDeleteOrder = () => {
    alert('Удаление не доступно')
  };


  const handleOpenEditOrder = () => {
    setOpenEditOrderModal(true)
  };
  const handleCloseEditOrder = () => {
    setOpenEditOrderModal(false)
  };


  const get_pay_methods = (pay_methods) => {
    let pay_methods_text = "";
    if (pay_methods && Array.isArray(pay_methods)) {
      pay_methods_text = pay_methods
        .map((pay_method) => pay_method.name)
        .join(", ");
    }
    return pay_methods_text;
  };

  return (
    <Grid
      border="1px solid black"
      height={100}
      margin={{ xs: 0, sm: 0, md: 1, lg: 1, xl: 1 }}
      marginTop={{ xs: 0.5, sm: 1, md: 1, lg: 1, xl: 1 }}
      display="block"
      padding={0.5}
      item
      xs={12}
      sm={5.8}
      md={3.7}
      lg={3.7}
      xl={3.8}
    >
      <EditOrderForm
        handleClose={handleCloseEditOrder}
        order_id={order?.id}
        isOpen={openEditOrderModal}
      />
      <Grid container item xs={12} justifyContent="space-between">
        <Grid>Курс:</Grid>
        <Grid justifyContent="center" alignItems="center">
          {order.amount} {order.currency_name} за 1 {order.token_name}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid>Всего:</Grid>
        <Grid justifyContent="center" alignItems="center">
          {get_friendly_token(order.count_token, order.token_name)}{" "}
          {order.token_name}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid>Лимиты:</Grid>
        <Grid justifyContent="center" alignItems="center">
          {order.min_limit} -{" "}
          {get_max_limit(order.count_token, order.token_name, order.amount)}{" "}
          {order.currency_name}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid>Оплата:</Grid>
        <Grid justifyContent="center" alignItems="center">
          {get_pay_methods(order.pay_methods)}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        height={25}
        borderTop="1px solid black"
        alignItems="center"
        justifyContent="space-around"
      >
        <Grid item>
          <Button style={{ padding: 0, margin: 0 }} onClick={handleActiveOrder}>
            Скрыть
          </Button>
        </Grid>
        <Grid item>
          <Button style={{ padding: 0, margin: 0 }} onClick={handleOpenEditOrder}>
            Редактировать
          </Button>
        </Grid>
        <Grid item>
          <Button style={{ padding: 0, margin: 0 }} onClick={handleOpenDeleteOrder}>
            Удалить
          </Button>
        </Grid>

      </Grid>
    </Grid>

  );
};
