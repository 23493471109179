import { Button, Grid } from "@mui/material";
import { get_friendly_token } from "../../../service/calc";
import { useState } from "react";
import { getFormattedDate } from "../../../service/date";
import { WalletHistoryModal } from "../../Modals/WalletHistoryModal";

export const WalletHistoryMobileBlock = ({ operation }) => {
  const [openHistoryModal, setHistoryModalOpen] = useState(false);

  const handleOpen = () => {
    setHistoryModalOpen(true);
  };

  const handleClose = () => {
    setHistoryModalOpen(false);
  };

  const getType = (type) => {
    switch (type) {
      case "in":
        return "Зачисление";
      case "out":
        return "Исходящая";
      case "from_ESCROW":
        return "Разморозка";
      case "to_ESCROW":
        return "Заморозка";
      default:
        return "";
    }
  };
  return (
    <Grid
      border="1px solid black"
      height={95}
      margin={{ xs: 0, sm: 0, md: 1, lg: 1, xl: 1 }}
      marginTop={{ xs: 0.5, sm: 1, md: 1, lg: 1, xl: 1 }}
      display="block"
      padding={0.5}
      item
      xs={12}
      sm={5}
      md={3}
      lg={3}
      xl={2}
    >
      <WalletHistoryModal
        operation={operation}
        handleClose={handleClose}
        open={openHistoryModal}
      />
      <Grid
        container
        item
        xs={12}
        height={25}
        borderBottom="1px solid black"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={9}>
          {getType(operation?.type)}
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="flex-end">
          <Button style={{ padding: 0, margin: 0 }} onClick={handleOpen}>
            Открыть
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid>Токен:</Grid>
        <Grid justifyContent="center" alignItems="center">
          {operation.token_name}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid>Всего:</Grid>
        <Grid justifyContent="center" alignItems="center">
          {get_friendly_token(operation.count_token, operation.token_name)}{" "}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid>Дата:</Grid>
        <Grid justifyContent="center" alignItems="center">
          {getFormattedDate(operation?.created_on)}
        </Grid>
      </Grid>
    </Grid>
  );
};
