import { useEffect, useState } from "react";
import { OrderAPI } from "../../../API/order";
import {
  Button,
  Chip,
  Grid,
  OutlinedInput,
  styled,
  useTheme,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { OrderBlock } from "../../Blocks/Order/OrderBlock";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { CreateDealModal } from "../../Modals/CreateDealModal";
import { CreateOrderModal } from "../../Modals/CreateOrderModal";
import Box from "@mui/material/Box";
import { payMethods } from "../../../service/calc";
import * as React from "react";
import { UserOrderBlock } from "../../Blocks/Order/UserOrderBlock";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const CircularButton = styled(Button)(({ theme }) => ({
  background: "rgba(0,74,255,0.75)",
  color: "rgb(255,255,255)",
  borderRadius: "50%",
  width: 56,
  height: 56,
  minWidth: 56,
  padding: 0,
  position: "relative",
  "& .MuiButton-label": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

// Новый стиль для убирания padding у элемента .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
const NoPaddingSelect = styled(Select)({
  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: 0,
    border: "none",
  },
});

export const OrderTable = ({ userOrders }) => {
  const theme = useTheme();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("RUB");
  const [selectedToken, setSelectedToken] = useState("TON");
  const [isMobile] = useState(window.innerWidth <= 768);
  const [openCreateOrderModal, setOpenCreateOrderModal] = useState(false);
  const [openMyOrderModal, setOpenMyOrderModal] = useState(false);

  const [typeOrder, setTypeOrder] = useState("sell");
  const [selectedPayMethods, setSelectedPayMethods] = React.useState([]);

  useEffect(() => {
    const orderAPI = new OrderAPI();
    if (!userOrders) {
      orderAPI
        .getOrders({
          type_order: typeOrder,
        })
        .then((response) => {
          if (response.status === 200) {
            setOrders(response.data.orders);
            setFilteredOrders(response.data.orders);
          } else if (!response.status) {
            setOrders([]);
            setFilteredOrders([]);
          }
        })
        .catch((error) => console.error("Error:", error));
    } else {
      orderAPI
        .getUserOrders({
          limit: 100,
          offset: 0,
          type_order: typeOrder,
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            setOrders(response.data.orders);
            setFilteredOrders(response.data.orders);
          } else if (!response.status) {
            setOrders([]);
            setFilteredOrders([]);
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  }, [typeOrder, userOrders]);

  const handleTypeOrderChange = (event) => {
    const value = event.target.value;
    setTypeOrder(value);
  };
  const handleCurrencyChange = (event) => {
    const value = event.target.value;
    setSelectedCurrency(value);
    if (!value) {
      setFilteredOrders(orders);
      return;
    }
    const filtered = orders.filter((order) =>
      order.currency_name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredOrders(filtered);
  };
  const handleTokenChange = (event) => {
    const value = event.target.value;
    setSelectedToken(value);
    if (!value) {
      setFilteredOrders(orders);
      return;
    }
    const filtered = orders.filter((order) =>
      order.token_name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredOrders(filtered);
  };
  const handleCloseOrder = () => {
    setOpenCreateOrderModal(false);
  };
  const handleCreateOrder = () => {
    // Добавьте здесь логику для создания нового заказа
    setOpenCreateOrderModal(true);
  };

  const handleSelectedPayMethod = (event) => {
    const {
      target: { value },
    } = event;

    // Преобразуем строку в массив, если выбран только один метод оплаты
    const selectedMethods = typeof value === "string" ? [value] : value;

    setSelectedPayMethods(selectedMethods);

    const filtered = orders.filter((order) => {
      if (selectedMethods.length === 0) {
        return true;
      } else {
        const orderPayMethods = order.pay_methods.map((method) => method.name);
        return selectedMethods.some((selectedMethod) =>
          orderPayMethods.includes(selectedMethod),
        );
      }
    });

    setFilteredOrders(filtered);
  };
  return (
    <Grid container>
      <CreateOrderModal
        handleClose={handleCloseOrder}
        open={openCreateOrderModal}
      />
      <Grid
        container
        item
        style={{
          overflowX: "auto",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          padding: 1,
        }}
        xs={12}
        md={8}
      >
        <Grid item>
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel>Тип</InputLabel>
            <Select
              value={typeOrder}
              onChange={handleTypeOrderChange}
              label="typeOrder"
            >
              <MenuItem value="sell">Покупка</MenuItem>
              <MenuItem value="buy">Продажа</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Валюта
            </InputLabel>
            <Select
              value={selectedCurrency}
              onChange={handleCurrencyChange}
              label="Валюта"
            >
              <MenuItem value="RUB">RUB</MenuItem>
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="UAH">UAH</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Токен
            </InputLabel>
            <Select
              value={selectedToken}
              onChange={handleTokenChange}
              label="Токен"
            >
              <MenuItem value="TON">TON</MenuItem>
              <MenuItem value="BTC">BTC</MenuItem>
              <MenuItem value="USDT">USDT</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item width="200px" height="48px">
          <FormControl sx={{ width: 300, height: "48px" }}>
            <NoPaddingSelect
              sx={{
                height: "48px",
              }}
              multiple
              displayEmpty
              value={selectedPayMethods}
              onChange={handleSelectedPayMethod}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <em style={{ fontStyle: "normal", color: "black" }}>
                      Все методы
                    </em>
                  );
                }

                return selected.join(", ");
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {payMethods.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, payMethods, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </NoPaddingSelect>
          </FormControl>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={handleCreateOrder}
            style={{
              background: "none",
              color: "black",
            }}
          >
            Создать
          </Button>
        </Grid>
      )}
      <Grid
        container
        item
        padding={0}
        margin={0}
        justifyContent={{
          xs: "space-between",
          sm: "none",
          md: "space-between",
          lg: "space-between",
          xl: "space-between",
        }}
      >
        {filteredOrders?.length !== 0 ? (
          filteredOrders.map((order) => (
            userOrders ? (
              <UserOrderBlock key={order.id} order={order} />
            ) : (
              <OrderBlock key={order.id} order={order} />
            )
          ))
        ) : (
          <div style={{ padding: "10px" }}>Объявлений нет</div>
        )}
      </Grid>
      {isMobile && (
        <Grid
          item
          xs={12}
          sx={{
            position: "fixed",
            bottom: 80,
            right: 16,
          }}
        >
          <CircularButton variant="contained" onClick={handleCreateOrder}>
            <AddCircleOutlineOutlined
              style={{ width: "32px", height: "32px" }}
            />
          </CircularButton>
        </Grid>
      )}
    </Grid>
  );
};
