import { Grid } from "@mui/material";
import { AccountTable } from "../../../components/Tables/Users/UserMenu";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SecureBlock } from "../../../components/Blocks/Account/SecureBlock";
import { SessionTable } from "../../../components/Tables/Sessions/SessionTable";
import { NotificationTable } from "../../../components/Tables/Notification/NotificationTable";
import { TotalTable } from "../../../components/Tables/Total/TotalBalance";
import { NewsTable } from "../../../components/Tables/News/NewsTable";

export function AccountPage() {
  const [chapter, setChapter] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");
    if (tabParam) {
      setChapter(tabParam);
    } else {
      setChapter("secure");
      searchParams.set("tab", "secure");
      navigate(`?${searchParams.toString()}`);
    }
  }, [location.search, navigate]);
  const handleSetChapter = (value) => {
    setChapter(value);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", value);
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <Grid container item spacing={1}>
      {!isMobile && (
        <Grid item xs={12} md={2}>
          <AccountTable handleSetChapter={handleSetChapter} />
        </Grid>
      )}
      <Grid item xs={isMobile ? 12 : 10} minHeight={600}>
        {chapter === "secure" && (
          <Grid container item>
            <Grid item>
              <SecureBlock />
            </Grid>
            <Grid item xs={12}>
              <SessionTable />
            </Grid>
          </Grid>
        )}
        {/*  Уведомления */}
        {chapter === "notification" && (
          <Grid container item>
            <Grid item xs={12}>
              <NotificationTable />
            </Grid>
          </Grid>
        )}
        {/*  Объемы */}
        {chapter === "total" && (
          <Grid container item>
            <Grid item xs={12}>
              <TotalTable />
            </Grid>
          </Grid>
        )}
        {/*  Объемы */}
        {chapter === "news" && (
          <Grid container item>
            <Grid item xs={12}>
              <NewsTable />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
