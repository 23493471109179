import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/joy";

export const SelectOne = ({
  values,
  handleChange,
  title,
  selected,
  error,
  errorText,
  disabled = false,
}) => {
  return (
    <div>
      <FormControl sx={{ minWidth: 150 }} fullWidth>
        <InputLabel id="demo-simple-select-helper-label">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={selected || ""}
          label={title}
          onChange={handleChange}
          error={error}
          disabled={disabled}
        >
          {values.map((value) => (
            <MenuItem key={value.key} value={value.key}>
              {value.name}
            </MenuItem>
          ))}
        </Select>
        {errorText ? (
          <FormHelperText
            style={{
              color: "red",
            }}
          >
            {errorText}
          </FormHelperText>
        ) : (
          <></>
        )}
      </FormControl>
    </div>
  );
};
