export const getFormattedDate = (created_on) => {
  if (!created_on) return;
  const date = new Date(created_on);

  // Получаем компоненты даты и времени
  const day = date.getDate();
  const month = date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0, поэтому добавляем 1
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Формируем строку в нужном формате
  return `${day < 10 ? "0" + day : day}.${month < 10 ? "0" + month : month}.${year} ${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
};
