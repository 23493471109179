import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  DiamondOutlined,
  CurrencyBitcoinOutlined,
  AttachMoneyOutlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { get_friendly_token } from "../../../service/calc";
import { useState } from "react";
import { WalletUpModal } from "../../Modals/WalletUpModal";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center", // Центрируем содержимое по горизонтали и вертикали
}));

const CVGData = {
  TON: <DiamondOutlined />,
  BTC: <CurrencyBitcoinOutlined />,
  USDT: <AttachMoneyOutlined />,
};
export const WalletBlock = ({ wallet }) => {
  const [isMobile] = useState(window.innerWidth >= 768);
  const [openWalletUpModal, setWalletUpModalOpen] = useState(false);

  const handleOpen = () => {
    setWalletUpModalOpen(true);
  };

  const handleClose = () => {
    setWalletUpModalOpen(false);
  };
  return (
    <Grid
      item
      xs={12}
      style={{
        width: isMobile ? "100%" : "320px",
      }}
    >
      <WalletUpModal
        wallet={wallet}
        handleClose={handleClose}
        open={openWalletUpModal}
      />
      <Grid container spacing={2} style={{ height: "100%" }}>
        <Grid item xs={3}>
          <Item style={{ height: "99%" }}>
            <div style={{ display: "inline-block" }}>
              <div>{CVGData[wallet?.token_name]}</div>
              <div>{wallet?.token_name}</div>
            </div>
          </Item>
        </Grid>
        <Grid item xs={9}>
          <Item style={{ height: "99%" }}>
            <Grid container item xs={12} spacing={1}>
              <Grid container item xs={12} justifyContent="space-between">
                <Grid>Баланс:</Grid>
                <Grid justifyContent="center" alignItems="center">
                  {get_friendly_token(wallet?.balance, wallet?.token_name)}
                </Grid>
              </Grid>

              <Grid container item xs={12} justifyContent="space-between">
                <Grid>Escrow:</Grid>
                <Grid justifyContent="center" alignItems="center">
                  {get_friendly_token(wallet?.escrow, wallet?.token_name)}
                </Grid>
              </Grid>
            </Grid>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item
            style={{
              display: "flex",
              justifyContent: "space-around",
              height: "75%",
            }}
          >
            <Button style={{ padding: 0, margin: 0 }} onClick={handleOpen}>
              Пополнить
            </Button>
            <Button style={{ padding: 0, margin: 0 }}>Вывести</Button>
          </Item>
        </Grid>
      </Grid>
    </Grid>
  );
};
