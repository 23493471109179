import { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DealBlock } from "../../Blocks/Deal/DealBlock";
import { DealAPI } from "../../../API/deal";
import { SocketDeal } from "../../../service/socketDeal";

export const DealTable = () => {
  const [deals, setDeals] = useState(null);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [selectedToken, setSelectedToken] = useState('');
  const [statusOnlyActive, setStatusOnlyActive] = useState(true);

  const socketDealRef = useRef(null);
  const dealsRef = useRef(deals);

  useEffect(() => {
    const dealAPI = new DealAPI();
    dealAPI
      .getDeals(statusOnlyActive)
      .then((data) => {
        if (data.status === 200) {
          setDeals(data.data.data);
          setFilteredDeals(data.data.data);
          dealsRef.current = data.data.data; // Обновляем dealsRef
        } else if (data?.response?.status === 404) {
          setDeals([]);
          setFilteredDeals([]);
          dealsRef.current = []; // Обновляем dealsRef
        }
      })
      .catch((error) => {});
  }, [statusOnlyActive]);

  const handleNewUpdate = (data) => {
    console.log("Received new update:", data);
    if (dealsRef.current !== null) {
      const existingDealIndex = dealsRef.current.findIndex(
        (deal) => deal.id === data.id,
      );

      if (existingDealIndex !== -1) {
        const updatedDeals = [...dealsRef.current];
        updatedDeals[existingDealIndex] = data;
        setDeals(updatedDeals);
        const filtered = applyFilters(updatedDeals);
        setFilteredDeals(filtered);
      } else {
        const updatedDeals = [...dealsRef.current, data];
        setDeals(updatedDeals);
        const filtered = applyFilters(updatedDeals);
        setFilteredDeals(filtered);
      }
    } else {
      setDeals([data]);
      setFilteredDeals([data]);
    }
  };

  useEffect(() => {
    if (!socketDealRef.current) {
      socketDealRef.current = new SocketDeal();
      socketDealRef.current.on("update_deal", handleNewUpdate);
    }

    return () => {
      if (socketDealRef.current) {
        socketDealRef.current.close();
      }
    };
  }, []); // Подключение к сокету происходит только при первом рендере

  useEffect(() => {
    if (deals !== null) {
      setFilteredDeals(applyFilters(deals));
    }
  }, [deals, statusOnlyActive, selectedCurrency, selectedToken]);

  const applyFilters = (deals) => {
    let filtered = [...deals];

    if (filtered.length === 0) {
      return filtered;
    }
    filtered = filtered.filter((deal) =>
      statusOnlyActive
        ? [
          'В обработке',
          'Создано',
          'Ожидается оплата',
          'Ожидается подтверждение',
          'Завершение',
          'Обжалование',
        ].includes(deal.status)
        : deal
    );
    if (selectedCurrency) {

      filtered = filtered.filter((deal) =>
        deal.currency_name
          .toLowerCase()
          .includes(selectedCurrency.toLowerCase()),
      );
    }

    if (selectedToken) {
      filtered = filtered.filter((deal) =>
        deal.token_name.toLowerCase().includes(selectedToken.toLowerCase()),
      );
    }

    return filtered;
  };

  const handleCurrencyChange = (event) => {
    const value = event.target.value;
    setSelectedCurrency(value);
    if (!value) {
      setFilteredDeals(deals);
      return;
    }
    const filtered = deals.filter((deal) =>
      deal.currency_name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredDeals(filtered);
  };

  const handleTokenChange = (event) => {
    const value = event.target.value;
    setSelectedToken(value);
    if (!value) {
      setFilteredDeals(deals);
      return;
    }
    const filtered = deals.filter((deal) =>
      deal.token_name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredDeals(filtered);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setStatusOnlyActive(value);
  };

  return (
    <Grid item xs={12} padding={0} margin={0} marginBottom={1}>
      <Grid container item xs={12}>
        <div
          style={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            marginBottom: "10px",
            padding: 1,
          }}
        >
          <FormControl
            variant="standard"
            sx={{ minWidth: 120, marginRight: "10px" }}
          >
            <InputLabel>Статус</InputLabel>
            <Select
              value={statusOnlyActive}
              onChange={handleStatusChange}
              label="Status"
            >
              <MenuItem value={true}>Активные</MenuItem>
              <MenuItem value={false}>Исполнены</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ minWidth: 120, marginRight: "10px" }}
          >
            <InputLabel id="demo-simple-select-standard-label">
              Валюта
            </InputLabel>
            <Select
              value={selectedCurrency}
              onChange={handleCurrencyChange}
              label="Age"
            >
              <MenuItem value="RUB">RUB</MenuItem>
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="UAH">UAH</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ minWidth: 120, marginRight: "10px" }}
          >
            <InputLabel id="demo-simple-select-standard-label">
              Токен
            </InputLabel>
            <Select
              value={selectedToken}
              onChange={handleTokenChange}
              label="Age"
            >
              <MenuItem value="TON">TON</MenuItem>
              <MenuItem value="BTC">BTC</MenuItem>
              <MenuItem value="USDT">USDT</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid container item xs={12}>
        {filteredDeals?.map((deal) => (
          <DealBlock key={deal?.id} deal={deal} />
        ))}
      </Grid>
    </Grid>
  );
};
