import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { NotificationAPI } from "../../../API/notif";

const notificationData = [
  {
    name: "Новая авторизация",
    telegram: true,
    phone: true,
    email: true,
  },
];
const names = {
  new_login: 'Новая авторизация',
  add_balance: 'Пополнение бананса',
  output_tokens: 'Вывод токенов',
  new_deal: 'Новая сделка',
  cancel_order: 'Отмена заявки',
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export const NotificationTable = () => {
  const [isMobile] = useState(window.innerWidth <= 768);
  const [notifications, setNotifications] = useState([]);
  const notificationAPI = new NotificationAPI()

  useEffect(() => {
    notificationAPI.getNotificationSettings()
      .then((response) => {
        const transformedNotifications = Object.keys(names).map(key => ({
          name: names[key],
          telegram: response.data.telegram[key] === true || response.data.telegram[key] === null ? response.data.telegram[key] : false,
          phone: response.data.phone[key] === true || response.data.phone[key] === null ? response.data.phone[key] : false,
          email: response.data.email[key] === true || response.data.email[key] === null ? response.data.email[key] : false,
        }));
        setNotifications(transformedNotifications);
        console.log(response.data)
        console.log(transformedNotifications)
      })
      .catch((error) => {
        // Обработка ошибок
      });
  }, []);


  const handleNotificationChange = (name, key) => {
    const updatedNotifications = notifications.map((notification) => {
      if (notification.name === name) {
        const type = getKeyByValue(names, name);
        let updateData ={
          value: !notification[key],
          method: key,
          type: type,
        }
        notificationAPI.updateNotificationSettings(updateData)
          .then((response) => console.log(response.status))
        return {
          ...notification,
          [key]: !notification[key],
        };
      }
      return notification;
    });

    setNotifications(updatedNotifications);
  };
  return (
    <Grid container marginTop={"15px"}>
      <Grid item xs={12}>
        {isMobile ? (
          notifications.map((notification, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              style={{
                border: "1px solid black",
                background: "#ececec",
                borderRadius: "10px",
                margin: "2%",
                padding: "8px",
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ borderBottom: "1px solid black", height: "25px" }}
              >
                {notification?.name}
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: "8px" }}
              >
                <Grid item>Email:</Grid>
                <Grid item>
                  <Switch
                    disabled={notification.email === null}
                    checked={notification.email}
                    size="small"
                    onChange={() =>
                      handleNotificationChange(notification.name, "email")
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>Телеграм:</Grid>
                <Grid item>
                  <Switch
                    disabled={notification.telegram === null}
                    checked={notification.telegram}
                    size="small"
                    onChange={() =>
                      handleNotificationChange(notification.name, "telegram")
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>Телефон:</Grid>
                <Grid item>
                  <Switch
                    disabled={notification.phone === null}
                    checked={notification.phone}
                    size="small"
                    onChange={() =>
                      handleNotificationChange(notification.name, "phone")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell align="center">Telegram</TableCell>
                  <TableCell align="center">Телефон</TableCell>
                  <TableCell align="center">Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications.map((notification, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {notification.name}
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        disabled={notification.telegram === null}
                        checked={notification.telegram}
                        onChange={() =>
                          handleNotificationChange(
                            notification.name,
                            "telegram",
                          )
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        disabled={notification.phone === null}
                        checked={notification.phone}
                        onChange={() =>
                          handleNotificationChange(notification.name, "phone")
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        disabled={notification.email === null}
                        checked={notification.email}
                        onChange={() =>
                          handleNotificationChange(notification.name, "email")
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};
